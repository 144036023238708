import React, { useMemo } from "react"
import EChartsReact from "@/components/EChartsReact"
import moment from "moment"
import { useSummaryData } from "../hooks/useSummaryData"
import { ClassName_EUIRollingCostsCharts } from "./summaryChartClassnames"

export const config = {
  title: 'Monthly Electricity and Gas Costs',
}

export const EUIRollingCostsCharts = ({ period, endingMonth }) => {

  const { data: electricityData = {
    electricity: [],
  } } = useSummaryData('electricity', period, endingMonth)
  const { data: gasData = {
    gas: []
  } } = useSummaryData('gas', period, endingMonth)

  const option = {
    backgroundColor: '#fff',
    title: {
      text: config.title,
      left: 'center',
      top: '2%',
      textStyle: {
        color: '#396598',
        fontSize: 14
      }
    },
    legend: {
      data: ['Electricity', 'Gas'],
      left: 'center',
      top: '10%',
      itemWidth: 10,
      itemHeight: 10
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: [...new Set([...electricityData.electricity, ...gasData.gas].map(item => item.month))],
        axisLabel: {
          rotate: 50,
          formatter: function(value) {
            return moment(value).format('MMM');
          }
        },
        position: 'bottom'
      },
      {
        type: 'category',
        position: 'top',
        boundaryGap: true,
        axisLine: {
          show: true
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          formatter: function(value) {
            return moment(value).format('YYYY');
          }
        },
        data: [...new Set([...electricityData.electricity, ...gasData.gas].map(item => item.month))]
      }
    ],
    yAxis: {
      type: 'value',
      position: 'right',
      axisLabel: {
        formatter: value => `$${(value/1000).toFixed(0)}K`
      }
    },
    series: [
      {
        data: electricityData.electricity.map(item => item.costs),
        type: 'bar',
        name: 'Electricity',
        stack: 'total',
        itemStyle: {
          color: '#4472c4',
          borderRadius: [0, 0, 0, 0]
        },
        barGap: 0
      },
      {
        data: gasData.gas.map(item => item.costs),
        name: 'Gas',
        type: 'bar',
        stack: 'total',
        itemStyle: {
          color: '#c00000',
          borderRadius: [6, 6, 0, 0]
        },
        barGap: 0
      }
    ],
    grid: {
      top: '20%',
      left: '6%',
      right: '6%',
      bottom: '4%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      formatter: function(params) {
        const date = moment(params[0].axisValue).format('MMM YYYY');
        let result = `${date}<br/>`;
        
        let total = 0;
        params.forEach(param => {
          const value = param.value;
          const marker = param.marker;
          const seriesName = param.seriesName;
          const formattedValue = `$ ${Math.round(value).toLocaleString()}`;
          total += value;
          
          result += `${marker}${seriesName}: ${formattedValue}<br/>`;
        });
        
        result += `Total: $ ${Math.round(total).toLocaleString()}`;
        
        return result;
      }
    }
  };

  return (
    <EChartsReact className={ClassName_EUIRollingCostsCharts} option={option} style={{ width: '100%', height: '100%' }} />
  )
}