import { config as ConsumptionAndCostsChartsConfig } from "./ConsumptionAndCostsCharts";
import { config as NormalizedWeatherChartsConfig } from "./NormalizedWeatherCharts";
import { config as EUIRolling12MonthTrendChartConfig } from "./EUIRolling12MonthTrendChart";
import { config as EUIRollingCostsChartsConfig } from "./EUIRollingCostsCharts";
import { config as KeyStatisticsConfig } from "./KeyStatistics";

export const ClassName_MONTHLY_ELECTRICITY_USAGE_COSTS = 'ChartsClassName-MonthlyElectricityUsageCosts';
export const ClassName_MONTHLY_GAS_USAGE_COSTS = 'ChartsClassName-MonthlyGasUsageCosts';
export const ClassName_NORMALIZED_ELECTRICITY_USAGE_OAT = 'ChartsClassName-NormalizedElectricityUsageOAT';
export const ClassName_NORMALIZED_GAS_USAGE_OAT = 'ChartsClassName-NormalizedGasUsageOAT';
export const ClassName_EUIRolling12MonthTrend = 'ChartsClassName-EUIRolling12MonthTrend';
export const ClassName_EUIRollingCostsCharts = 'ChartsClassName-EUIRollingCostsCharts';
export const ClassName_KEY_STATISTICS = 'ChartsClassName-KeyStatistics';
export const ClassName_EUIkBtuPersqftyr = 'ChartsClassName-EUIkBtuPersqft-yr';
export const ClassName_EUIBreakdownkBtuPersqftyr = 'ChartsClassName-EUIBreakdownkBtuPersqft-yr';

export const summaryChartClassnames = [
    {
        id: ClassName_MONTHLY_ELECTRICITY_USAGE_COSTS,
        title: ConsumptionAndCostsChartsConfig.electricity.title
    },
    {
        id: ClassName_MONTHLY_GAS_USAGE_COSTS,
        title: ConsumptionAndCostsChartsConfig.gas.title
    },
    {
        id: ClassName_NORMALIZED_ELECTRICITY_USAGE_OAT,
        title: NormalizedWeatherChartsConfig.electricity.title
    },
    {
        id: ClassName_NORMALIZED_GAS_USAGE_OAT,
        title: NormalizedWeatherChartsConfig.gas.title
    },
    {
        id: ClassName_EUIRollingCostsCharts,
        title: EUIRollingCostsChartsConfig.title
    },
    {
        id: ClassName_EUIRolling12MonthTrend,
        title: EUIRolling12MonthTrendChartConfig.title
    },
    {
        id: ClassName_KEY_STATISTICS,
        title: KeyStatisticsConfig.title
    },
    {
        id: ClassName_EUIkBtuPersqftyr,
        title: 'EUI (kBtu/sqft-yr)'
    },
    {
        id: ClassName_EUIBreakdownkBtuPersqftyr,
        title: 'EUI Breakdown (kBtu/sqft-yr)'
    }
]