import moment from 'moment';
import actions from './actions';

const initState = {
    dateRange: {}
};

export default function GlobalReducer(state = initState, action) {
    switch (action.type) {
        case actions.Date_Range:
            return {
                ...state,
                dateRange: safeDateRange(action.payload.data)
            };
        default:
            return state;
    }
}


function safeDateRange(dateRange) {
    return {
        date_range_elec_min: dateRange.date_range_elec_min === 'None' ? moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DD') : dateRange.date_range_elec_min,
        date_range_elec_max: dateRange.date_range_elec_max === 'None' ? moment().startOf('month').format('YYYY-MM-DD') : dateRange.date_range_elec_max,
        date_range_gas_min: dateRange.date_range_gas_min === 'None' ? moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DD') : dateRange.date_range_gas_min,
        date_range_gas_max: dateRange.date_range_gas_max === 'None' ? moment().startOf('month').format('YYYY-MM-DD') : dateRange.date_range_gas_max,
    }
}
