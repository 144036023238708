import actions from './actions';
import { injectFacilityId } from './injectFacilityId';

const initState = {
    data: [],
    selected: {},
    geolocation: [],
    address: [],
    admin_facility: []
};

export default function FacilityReducer(state = initState, action) {
    switch (action.type) {
        case actions.Facility_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            };
        case actions.SELECTED_FACILITY:
            return {
                ...state,
                selected: action.payload.data
            };
        case actions.Selected_Address:
            return {
                ...state,
                address: action.payload.data
            };
        case actions.Selected_GeoLocation:
            return {
                ...state,
                geolocation: action.payload.data,
            };
        case actions.Selected_Admin_Facility:
            return {
                ...state,
                admin_facility: action.payload.data,
            };
        default:
            return state;
    }
}
