import React from "react";
import LayoutContent from "@components/utility/layoutContent";
import ReactECharts from "@/components/EChartsReact";

const BubblePlot = (props) => {
    const getOption = () => {
        return {
            title: {
                text: props.title,
                left: 'center',
                top: '20',
            },
            tooltip: {
                trigger: 'item',
                formatter: function(params) {
                    return `Year: ${params.data[0]}<br/>
                            EUI: ${params.data[1]}<br/>
                            Size: ${params.data[2]}`;
                }
            },
            xAxis: {
                name: props.xLabel,
                type: 'category',
                nameLocation: 'middle',
                nameGap: 30,
                axisLabel: {
                    hideOverlap: true,
                    rotate: 0
                }
            },
            yAxis: {
                name: props.yLabel,
                type: 'value',
                nameLocation: 'middle',
                nameGap: 40,
                scale: true
            },
            legend: {
                data: ['Current', 'Other'],
                top: 50
            },
            series: [{
                type: 'scatter',
                data: props.data.map(item => ([
                    item.xAxis,
                    item.yAxis,
                    item.size,
                    item.type,
                    item.isCurrent
                ])),
                symbolSize: function (data) {
                    // 根据size字段计算气泡大小，可以调整系数来改变气泡大小范围
                    return Math.sqrt(Math.sqrt(data[2])) / 2;
                },
                itemStyle: {
                    color: function(params) {
                        return params.data[4] ? 'orange' : '#1890ff';
                    },
                    opacity: 0.65
                },
                emphasis: {
                    focus: 'series',
                    label: {
                        show: true,
                        formatter: function (param) {
                            return param.data[3];
                        },
                        position: 'top'
                    }
                }
            }],
            grid: {
                top: '20%',
                left: '4%',
                right: '4%',
                bottom: '8%',
                containLabel: true
            }
        };
    };

    return (
        <LayoutContent className={"outerCardWrapper"}>
            <ReactECharts
                option={getOption()}
                style={{ height: '400px' }}
                notMerge={true}
                lazyUpdate={true}
            />
        </LayoutContent>
    );
};

export default BubblePlot;
