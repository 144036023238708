import React, { useEffect, useState } from 'react'
import ReactECharts from "echarts-for-react"

export default function EChartsReact({ ...props }) {

 const [isMounted, setIsMounted] = useState(false)

 useEffect(() => {
  setIsMounted(true)
 }, [])

  return isMounted ? <ReactECharts {...props} /> : null
}
