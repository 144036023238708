import { api, update_profile } from '@/redux/api';
import { useRequest } from 'ahooks';
import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Result, Button, Spin, Card, Form, Typography, message, Space, notification } from 'antd';
import UserDetailFormFields from '../components/UserDetailFormFields';
import _ from 'lodash';
import { useResetPassword } from '@/hooks/useResetPassword';
import { UnlockOutlined } from '@ant-design/icons';
import { useKeepAliveContext } from '@/hooks/use-keep-alive-context';
import { EventName, useEventBus } from '@/hooks/useEventBus';

const { Title } = Typography;

const UserDetail = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [isEditing, setIsEditing] = useState(false);
    const { loading, resetPassword } = useResetPassword();
    const { closeTab, renameTab } = useKeepAliveContext();
    // 格式化表单数据
    const formatFormData = useCallback((data) => {
        return {
            ...data,
            profile: {
                label: data.profile_name,
                value: data.profile
            },
            company: {
                label: data.company_name,
                value: data.company
            },
        };
    }, []);

    const { data, loading: fetchLoading, error, runAsync: refetch } = useRequest(
        () => api.getCustomUser(id),
        {
            onSuccess: (data) => {
                form.setFieldsValue(formatFormData(data));
                renameTab('/UserDetail/' + data.id, data.username + ' - Details');
            },
            onError: (err) => {
                console.error('Failed to fetch user details:', err);
                message.error('Failed to fetch user details');
            }
        }
    );

    useEventBus(EventName.USER_DETAIL_REFRESH, refetch);

    const saveUser = async () => {
        form.validateFields().then(async ({ avatar, image, user = [], companies = [], profile, company, ...values }) => {
            const resultData = {
                profile,
                company,
                ...values,
            }
            if (profile?.value) {
                resultData.profile = profile.value;
            }
            if (company?.value) {
                resultData.company = company.value;
            }

            if (avatar) {
                resultData.image = avatar.originFileObj;
            }
            resultData.user = user.join(',');
            resultData.companies = companies.join(',');
            update_profile(data.id, resultData).then(res => {
                message.success('Update user successfully');
                refetch().then(() => {
                    setIsEditing(false);
                });
            })
        }).catch(err => {
            message.error(err.message);
        })
    };

    const handleCancel = () => {
        setIsEditing(false);
        if (data) {
            form.setFieldsValue(formatFormData(data));
        }
    };

    const handleResetPassword = () => {
        const email = data?.email
        if (email) {
            resetPassword(email);
        }
    };

    if (error) {
        return (
            <Result
                status="error"
                title="Failed to fetch user information"
                subTitle={error.message || 'Network error, please try again later'}
                extra={[
                    <div className='flex justify-center'>
                        <Button
                            type="primary"
                            key="back"
                            onClick={() => closeTab()}
                        >
                            Back
                        </Button>
                    </div>
                ]}
            />
        );
    }

    return (
        <Spin spinning={fetchLoading} tip="Loading...">
            <Card
                title={<Title level={4}>{data?.username ? `${data.username} - Details` : 'User Details'}</Title>}
                extra={
                    isEditing ? (
                        <>
                            <Space size={16}>

                                <Button onClick={handleCancel} style={{ marginRight: 26 }}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={saveUser}
                                    style={{ marginRight: 8 }}
                                >
                                    Save
                                </Button>
                            </Space>
                        </>
                    ) : (
                        <Space size={36}>
                            <Button
                                onClick={handleResetPassword}
                                icon={<UnlockOutlined />}
                                loading={loading}
                                style={{ marginRight: 26 }}
                            >
                                Reset Password
                            </Button>
                            <Button type="primary" onClick={() => setIsEditing(true)}>
                                Edit
                            </Button>
                        </Space>
                    )
                }
            >
                <Form
                    form={form}
                    layout="vertical"
                    disabled={!isEditing}
                >
                    <UserDetailFormFields disabled={!isEditing} defaultImage={data?.image} />
                </Form>
            </Card>
        </Spin>
    );
};

export default UserDetail;