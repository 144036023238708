const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT: 'LOGOUT',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    SELECT_USERPERMISSIONS: 'SELECT_USERPERMISSIONS',
    IPADDRESS: 'IPADDRESS',
    IS_IN_COMPANY: 'IS_IN_COMPANY',
    USERINFO_UPDATE_REQUEST: 'USERINFO_UPDATE_REQUEST',
    USERINFO_UPDATE_SUCCESS: 'USERINFO_UPDATE_SUCCESS',
    UPDATE_TOKEN: 'UPDATE_TOKEN',
    USER_UPDATE: 'USER_UPDATE',

    updateToken: (access, refresh, accessTokenExpiration, refreshTokenExpiration) => ({
        type: actions.UPDATE_TOKEN,
        payload: {access, refresh, accessTokenExpiration, refreshTokenExpiration}
    }),
    checkAuthorization: () => ({type: actions.CHECK_AUTHORIZATION}),
    login: (tokens) => ({
        type: actions.LOGIN_REQUEST,
        payload: {tokens},
    }),
    logout: (refresh, authToken) => ({
        type: actions.LOGOUT,
        payload: {refresh, authToken},
    }),
    selected_userpermissions: (data) => ({
        type: actions.SELECT_USERPERMISSIONS,
        payload: {data},
    }),
    ipaddress: (data) => ({
        type: actions.IPADDRESS,
        payload: {data},
    }),
    isInCompany: (data) => ({
        type: actions.IS_IN_COMPANY,
        payload: {data},
    }),
    userInfoUpdated: (tokens) => ({
        type: actions.USERINFO_UPDATE_REQUEST,
        payload: {tokens},
    }),
    userInfoUpdatedSuccess: (user) => ({
        type: actions.USERINFO_UPDATE_SUCCESS,
        payload: {user},
    }),
    userUpdate: (data = {}) => ({
        type: actions.USER_UPDATE,
        payload: data,
    }),
};
export default actions;
