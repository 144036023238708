import React, {Suspense} from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Loader from '@/components/utility/loader';

import { routes } from './routes';
import ErrorBoundary from '@/components/ErrorBoundary';


// This route will only be visible to users with a valid accessToken.
export const PRIVATE_ROUTE = {
    DASHBOARD: '/'
}

export default function Routes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader/>}>
                <RouterProvider router={createBrowserRouter(routes)} />
            </Suspense>
        </ErrorBoundary>
    );
}
