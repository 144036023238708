import React from "react";
import ReactECharts from "@/components/EChartsReact"

const ClassName_Savings_ElecOAT = 'ChartsClassName-Savings-ElecOAT';
const ClassName_Savings_GasOAT = 'ChartsClassName-Savings-GasOAT';

export const config = {
  gas: {
    title: 'Normalized Gas Usage Vs. OAT',
    yTitle: 'Btu/hr-sqft',
    xTitle: 'Monthly Avg. OAT (deg F)',
    baselineColor: '#c00000',
    postPeriodColor: '#ffc000',
    className: ClassName_Savings_GasOAT
  },
  elec: {
    title: 'Normalized Electricity Usage Vs. OAT',
    yTitle: 'Watt / sqft',
    xTitle: 'Monthly Avg. OAT (deg F)',
    baselineColor: '#4472c4',
    postPeriodColor: '#ffc000',
    className: ClassName_Savings_ElecOAT
  },
}

export const NormalizedUsageChart = ({ type, data, cv, r_squared, regression }) => {

  const chartClassname = type === 'elec' ? ClassName_Savings_ElecOAT : ClassName_Savings_GasOAT;

  // 将数据按类型分组
  const baselineData = data
    .filter(item => item.type === "Baseline")
    .map(item => [item.x, item.y]);

  const postPeriodData = data
    .filter(item => item.type === "Post Period")
    .map(item => [item.x, item.y]);

  // 计算数据的温度范围
  const allTemps = [...baselineData, ...postPeriodData].map(point => point[0]);
  const minTemp = Math.min(...allTemps);
  const maxTemp = Math.max(...allTemps);

  // 添加适当的边距
  const padding = 5; // 5°F的边距
  const xMin = Math.floor(minTemp - padding);
  const xMax = Math.ceil(maxTemp + padding);

  const option = {
    title: {
      text: config[type].title,
      left: '1%',
      top: '2%',
      textStyle: {
        color: '#396598',
        fontSize: 14
      }
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderColor: '#eee',
      borderWidth: 1,
      textStyle: {
        color: '#606266'
      },
      formatter: function (params) {
        // Find the original data point to get the date
        const point = data.find(d =>
          d.x === params[0].data[0] &&
          Math.abs(d.y - params[0].data[1]) < 0.0001
        );
        const date = point ? point.date : '';

        const dateHtml = date ? `<div>Date: ${date}</div>` : '';

        // if(params[0].seriesName === 'Regression') {
        //   return null
        // }
        return `<div style="padding: 3px;">
                    <div style="margin-bottom: 3px;"><b>${params[0].seriesName}</b></div>
                    <div>Temperature: ${params[0].data[0].toFixed(1)}°F</div>
                    <div>Usage: ${params[0].data[1].toFixed(3)} ${config[type].yTitle}</div>
                    ${dateHtml}
                </div>`;
      }
    },
    legend: {
      data: [
        {
          name: 'Baseline',
          icon: 'circle'
        },
        {
          name: 'Post Period',
          icon: 'circle'
        },
        {
          name: 'Regression',
          icon: 'line'
        }
      ],
      left: 'center',
      top: '12%',
      itemWidth: 10,
      itemHeight: 10
    },

    xAxis: {
      type: 'value',
      name: config[type].xTitle,
      nameLocation: 'middle',
      nameGap: 30,
      min: xMin,
      max: xMax,
      nameTextStyle: {
        fontWeight: 'bold',
        color: '#6e7079'
      },
      axisLabel: {
        formatter: '{value}°F',
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      name: config[type].yTitle,
      nameLocation: 'middle',
      nameGap: 30,
      nameTextStyle: {
        fontWeight: 'bold',
        color: '#6e7079'
      },
    },
    series: [
      {
        name: 'Baseline',
        type: 'scatter',
        data: baselineData,
        symbolSize: 8,
        itemStyle: {
          color: config[type].baselineColor,
          opacity: 0.8,
          borderColor: '#fff',
          borderWidth: 1
        }
      },
      {
        name: 'Post Period',
        type: 'scatter',
        data: postPeriodData,
        symbolSize: 8,
        itemStyle: {
          color: config[type].postPeriodColor,
          opacity: 0.8,
          borderColor: '#fff',
          borderWidth: 1
        }
      },
      {
        name: 'Regression',
        type: 'line',
        data: regression,
        showSymbol: false,
        itemStyle: {
          color: '#909399'
        },
        lineStyle: {
          type: 'solid',
          width: 2,
          opacity: 0.7
        }
      }
    ],
    graphic: [
      {
        type: 'group',
        right: '2%',
        top: 10,
        children: [
          {
            type: 'text',
            left: 10,
            right: 0,
            style: {
              text: `CV: ${cv ? (cv * 100).toFixed(1) : 'N/A'}%`,
              fontSize: 12,
              fontWeight: 'bold',
              fill: '#396598'
            }
          },
        ]
      },
      {
        type: 'group',
        right: '2%',
        top: 30,
        children: [
          {
            type: 'text',
            left: 10,
            right: 0,
            style: {
              text: `R²: ${r_squared ? (r_squared).toFixed(2) : 'N/A'}`,
              fontSize: 12,
              fontWeight: 'bold',
              fill: '#396598'
            }
          },
        ]
      },
    ],
    grid: {
      top: '20%',
      left: '6%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
  };

  return (
    <ReactECharts
      className={config[type].className}
      option={option}
      autoResize={true}
      style={{ height: '100%', width: '100%' }}
    />
  );
}