import React, { PureComponent, Suspense } from 'react';
import { LayoutContentWrapper } from "@components/utility/layoutWrapper.style.js";
import { Col, Popover, Row, Spin } from "antd";
import basicStyle from "@assets/styles/constants.js";
import { WidgetWrapper } from "@components/WidgetBox/Widgets.styles.js";
import _ from "lodash";
import { connect } from "react-redux";
import { NoDecimalFormat, PercentFormat } from "@components/Format";
import { api } from "@redux/api";
import workorder_actions from "@redux/workorder/actions";
import gas_actions from "@redux/gas/actions";
import electricity_actions from "@redux/electricity/actions";
import PeiChart from "@containers/facility/Overview/PieChart";
import { CaretDownOutlined, CaretUpOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Spinner } from "@components/Spinner";
import PermissionControl from "@components/Permission/Permission";
import ColumnChart from "@containers/UtilityData/Charts/ColumnChart";
import moment from "moment";
import { withRouter } from "@/router/withRouter";
import appActions from "@redux/app/actions";
import { DashBoardGaugeCharts } from '@/containers/UtilityData/New-Summary/components/GaugeCharts';
import DashboardCard from './components/DashbordCard';

const contentStyle = {
    widht: '100%',
    height: '100%',
    background: '#fff',
    borderRadius: 10,
    border: '1px solid #f0f0f0',
    aspectRatio: '2 / 1'
}

const colLayout = {
    xs: 24,
    lg: 12,
    xxl: 8,
    style: {
        padding: '4px',
        width: '100%',
        minHeight: 200, minWidth: 300
    }

}

const styles = {
    wisgetPageStyle: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'flex-start',
        overflow: 'hidden',
        width: '100%',
        justifyContent: 'center'
    },
};

const cardStyle = {
    height: '100%',
    background: '#fff',
    borderRadius: 10,
    border: '1px solid #f0f0f0',

    // aspectRatio: '7/3',
    minHeight: 140,
    maxHeight: 170,
    // maxWidth: '100%'
}

class Overview extends PureComponent {

    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            gas_latest_consumption: 0,
            gas_last_consumption: 0,
            gas_latest_consumption_diff: 0,
            gas_latest_cost: 0,
            gas_last_cost: 0,
            gas_latest_cost_diff: 0,
            gas_latest_service_month: '',
            gas_last_service_month: '',
            elec_latest_consumption: 0,
            elec_last_consumption: 0,
            elec_latest_consumption_diff: 0,
            elec_latest_cost_diff: 0,
            elec_latest_cost: 0,
            elec_last_cost: 0,
            elec_latest_service_month: '',
            elec_last_service_month: '',

            workorder: [],
            elecgas: [],
            equipment_condition: [],
            equipment_category: [],
            project: [],

            electricity_treemap_data: [],
            gas_treemap_data: [],
            past7_past30: [],

            isLoading: false,

            groupedMonthlyData: [],
            groupedPriorityData: [],
            groupedStatusData: [],

            total_eui: 0,
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.facility_id !== this.props.facility_id) {
            this.handleInitRender()
        }
    }

    componentDidMount() {
        this.handleInitRender()
    }

    handleInitRender = (e) => {
        // console.log('this.props.permission', this.props.permission)
        if (!this.props.company_selected) {
            return null
        }
        this.setState({
            isLoading: true
        })

        let apiCalls = [];

        if (this.props.permission?.workorder && this.props.permission?.workorder !== 0) {
            apiCalls.push(api.allworkorderview());
        }
        if (this.props.permission?.gas && this.props.permission?.gas !== 0) {
            apiCalls.push(api.getAllElecGasComparisionView());
        }
        if (this.props.permission?.equipment && this.props.permission?.equipment !== 0) {
            apiCalls.push(api.allequipmentview());
        }
        if (this.props.permission?.electricity && this.props.permission?.electricity !== 0) {

            apiCalls.push(api.get_electricity_summary(12));
            apiCalls.push(api.get_gas_summary(12));
        }

        if (this.props.permission?.gas && this.props.permission?.gas !== 0) {

            apiCalls.push(api.getElecGas());
            apiCalls.push(api.getFacilityConsumption(this.props.company_selected));
        }


        Promise.all(apiCalls)
            .then(response => {
                let index = 0;

                const resData = {
                    workorder: this.props.permission?.workorder && this.props.permission?.workorder !== 0 ? _.get(response[index++], 'data') : undefined,
                    elecGasComparision: this.props.permission?.gas && this.props.permission?.gas !== 0 ? _.get(response[index++], 'data') : undefined,
                    equipment: this.props.permission?.equipment && this.props.permission?.equipment !== 0 ? _.get(response[index++], 'data') : undefined,
                    electricity_summary: this.props.permission?.electricity && this.props.permission?.electricity !== 0 ? _.get(response[index++], 'data') : undefined,
                    gas_summary: this.props.permission?.gas && this.props.permission?.gas !== 0 ? _.get(response[index++], 'data') : undefined,
                    elecgas: this.props.permission?.gas && this.props.permission?.gas !== 0 ? _.get(response[index++], 'data.results') : undefined,
                    electricity_treemap: this.props.permission?.gas && this.props.permission?.gas !== 0 ? _.get(response[index], 'data.electricity') : undefined,
                    gas_treemap: this.props.permission?.gas && this.props.permission?.gas !== 0 ? _.get(response[index], 'data.gas') : undefined,
                };

                this.Init(resData.workorder, resData.elecGasComparision, resData.equipment, resData.electricity_summary, resData.gas_summary,
                    resData.elecgas, resData.electricity_treemap, resData.gas_treemap);


                this.setState({
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
            });

    }


    Init = (workorder_data, elecGasComparision, equipment_data, electricity_summary, gas_summary, elecgas_data, electricity_treemap, gas_treemap) => {
        let gas_comparision = elecGasComparision?.gas;
        let elec_comparision = elecGasComparision?.electricity;

        let electricity_treemap_data = electricity_treemap;
        let gas_treemap_data = gas_treemap;
        let equipment_condition = equipment_data?.grouped_status_data;
        let equipment_category = equipment_data?.grouped_category_data;
        let elecgas = elecgas_data;
        let total_eui = 0;
        total_eui = gas_summary.annual_eui + electricity_summary.annual_eui;
        let groupedPriorityData = workorder_data?.grouped_priority_data;
        let groupedStatusData = workorder_data?.grouped_status_data;
        let groupedMonthlyData = workorder_data?.sorted_monthly_data;
        let past7_past30 = workorder_data?.past7_past30;

        this.setState({
            gas_latest_consumption: gas_comparision?.latest_consumption,
            gas_last_consumption: gas_comparision?.last_consumption,
            gas_latest_cost: gas_comparision?.latest_cost,
            gas_last_cost: gas_comparision?.last_cost,
            gas_latest_consumption_diff: gas_comparision?.consumption_diff,
            gas_latest_cost_diff: gas_comparision?.cost_diff,
            gas_latest_service_month: gas_comparision?.latest_service_month,
            gas_last_service_month: gas_comparision?.last_service_month,

            elec_latest_consumption: elec_comparision?.latest_consumption,
            elec_last_consumption: elec_comparision?.last_consumption,
            elec_latest_consumption_diff: elec_comparision?.consumption_diff,
            elec_latest_cost_diff: elec_comparision?.cost_diff,
            elec_latest_cost: elec_comparision?.latest_cost,
            elec_last_cost: elec_comparision?.last_cost,
            elec_latest_service_month: elec_comparision?.latest_service_month,
            elec_last_service_month: elec_comparision?.last_service_month,

            groupedPriorityData,
            groupedStatusData,
            groupedMonthlyData,
            past7_past30,
            elecgas,
            electricity_treemap_data,
            gas_treemap_data,
            total_eui,
            equipment_condition,
            equipment_category,
        })
    }


    handleRender = (data) => {
        return {
            total_cost: _.get(data[0], 'costs'),
            prev_cost: _.get(data[1], 'costs'),
            total_usage: _.get(data[0], 'usage'),
            prev_usage: _.get(data[1], 'usage'),
        }
    }

    onChange = (e) => {
        this.setState({
            period: Number(e.target.value)
        }, function () {
            this.Init()
        })
    }

    renderCard = (title, label, value, prev_value, ratio, unit, color, latest_service_month, last_service_month) => {
        return (
            <WidgetWrapper>

                <div className={" p-6 bg-white shadow rounded-md corner-flower-" + color}>
                    <div className="flex mb-10 items-center justify-between">

                        <h3 className={'small-title '}>{title}</h3>
                        <Popover content={
                            <div>
                                {'percent change comparing to same month of previous year'}
                            </div>
                        } trigger="hover"
                        >
                            <InfoCircleOutlined style={{ color: 'orange' }} />
                        </Popover>
                    </div>
                    <div className="flex items-center mb-5">
                        <span className="text-5xl font-bold">{NoDecimalFormat(value)}</span>
                        <span className="text-3xl ml-1">{unit}</span>

                        <span
                            className={ratio > 0 ? 'bg-red-500 inline-block ml-2 py-1 px-2  rounded-full text-white'
                                : 'bg-green-500 inline-block ml-2 py-1 px-2  rounded-full text-white'}>
                            {PercentFormat(ratio)}
                        </span>


                    </div>
                    <div className="relative w-full h-1 mb-5  rounded">
                        <div className="absolute top-0 left-0 w-4/6 h-full bg-purple-500 rounded"></div>
                    </div>
                    <p className=" ">{moment(latest_service_month).format('YYYY MMM')} {ratio > 0 ?
                        <CaretUpOutlined style={{ fontSize: '16px', color: 'red' }} /> :
                        <CaretDownOutlined style={{ fontSize: '16px', color: 'green' }} />}
                        {' ' + moment(last_service_month).format('YYYY MMM')}
                    </p>
                </div>

            </WidgetWrapper>
        )
    }

    renderTopStats = () => {
        const { rowStyle, colStyle } = basicStyle;

        const colCardLayout = {
            xxl: 6,
            xl: 6,
            lg: 12,
            md: 12,
            sm: 12,
            xs: 24,
            className: 'my-4'
        }

        return (
            <>

                <Row style={rowStyle} gutter={8} justify="start" className={''}>
                    <PermissionControl allowedPermissions={'1:electricity'}>

                        <Col {...colCardLayout}>
                            <div style={cardStyle} className='Dashboard_cards_0'>
                                <DashboardCard
                                    variant='blue'
                                    title='Electricity Monthly Consumption'
                                    value={NoDecimalFormat(this.state.elec_latest_consumption)}
                                    unit='kWh'
                                    percent={PercentFormat(this.state.elec_latest_consumption_diff)}
                                    startDate={moment(this.state.elec_latest_service_month).format('YYYY MMM')}
                                    endDate={moment(this.state.elec_last_service_month).format('YYYY MMM')}
                                    isIncreased={this.state.elec_latest_consumption_diff > 0}
                                />
                            </div>
                        </Col>
                    </PermissionControl>
                    <PermissionControl allowedPermissions={'1:electricity'}>

                        <Col {...colCardLayout}>
                            <div style={cardStyle} className='Dashboard_cards_1'>
                                <DashboardCard
                                    variant='green'
                                    title='Electricity Monthly Cost'
                                    value={NoDecimalFormat(this.state.elec_latest_cost)}
                                    unit='$'
                                    percent={PercentFormat(this.state.elec_latest_cost_diff)}
                                    startDate={moment(this.state.elec_latest_service_month).format('YYYY MMM')}
                                    endDate={moment(this.state.elec_last_service_month).format('YYYY MMM')}
                                    isIncreased={this.state.elec_latest_cost_diff > 0}
                                />
                            </div>
                        </Col>
                    </PermissionControl>
                    <PermissionControl allowedPermissions={'1:electricity'}>

                        <Col {...colCardLayout}>
                            <div style={cardStyle} className='Dashboard_cards_2'>
                                <DashboardCard
                                    variant='purple'
                                    title='Gas Monthly Consumption'
                                    value={NoDecimalFormat(this.state.gas_latest_consumption)}
                                    unit='MMBtu'
                                    percent={PercentFormat(this.state.gas_latest_consumption_diff)}
                                    startDate={moment(this.state.gas_latest_service_month).format('YYYY MMM')}
                                    endDate={moment(this.state.gas_last_service_month).format('YYYY MMM')}
                                    isIncreased={this.state.gas_latest_consumption_diff > 0}
                                />
                            </div>
                        </Col>
                    </PermissionControl>
                    <PermissionControl allowedPermissions={'1:electricity'}>

                        <Col {...colCardLayout}>
                            <div style={cardStyle} className='Dashboard_cards_3'>
                                <DashboardCard
                                    variant='orange'
                                    title='Gas Monthly Cost'
                                    value={NoDecimalFormat(this.state.gas_latest_cost)}
                                    unit='$'
                                    percent={PercentFormat(this.state.gas_latest_cost_diff)}
                                    startDate={moment(this.state.gas_latest_service_month).format('YYYY MMM')}
                                    endDate={moment(this.state.gas_last_service_month).format('YYYY MMM')}
                                    isIncreased={this.state.gas_latest_cost_diff > 0}
                                />
                            </div>
                        </Col>
                    </PermissionControl>

                </Row>
                {/* <Row style={rowStyle} gutter={0} justify="start" className={''}>
                    <Col lg={6} md={12} sm={24} xs={24} className={'my-5'}>
                        <PermissionControl allowedPermissions={'1:electricity'}>
                            {this.renderCard('Electricity Monthly Consumption', 'Usage',
                                this.state.elec_latest_consumption,
                                this.state.elec_last_consumption,
                                this.state.elec_latest_consumption_diff, 'kWh', '',
                                this.state.elec_latest_service_month,
                                this.state.elec_last_service_month
                            )}
                        </PermissionControl>
                    </Col>
                    <Col lg={6} md={12} sm={24} xs={24} className={'my-5'}>
                        <PermissionControl allowedPermissions={'1:electricity'}>
                            {this.renderCard('Electricity Monthly Cost', 'Cost',
                                this.state.elec_latest_cost,
                                this.state.elec_last_cost,
                                this.state.elec_latest_cost_diff, '$', '',
                                this.state.elec_latest_service_month,
                                this.state.elec_last_service_month)}
                        </PermissionControl>
                    </Col>
                    <Col lg={6} md={12} sm={24} xs={24} className={'my-5'}>
                        <PermissionControl allowedPermissions={'1:electricity'}>
                            {this.renderCard('Gas Monthly Consumption', 'Usage',
                                this.state.gas_latest_consumption,
                                this.state.gas_last_consumption,
                                this.state.gas_latest_consumption_diff, 'MMBtu', '',
                                this.state.gas_latest_service_month,
                                this.state.gas_last_service_month)}
                        </PermissionControl>
                    </Col>
                    <Col lg={6} md={12} sm={24} xs={24} className={'my-5'}>
                        <PermissionControl allowedPermissions={'1:electricity'}>
                            {this.renderCard('Gas Monthly Cost', 'Cost',
                                this.state.gas_latest_cost,
                                this.state.gas_last_cost,
                                this.state.gas_latest_cost_diff, '$', '',
                                this.state.gas_latest_service_month,
                                this.state.gas_last_service_month)}
                        </PermissionControl>
                    </Col>
                </Row> */}

                <Row style={rowStyle} gutter={0} justify="start">

                    {/*<Col lg={24} md={24} sm={24} xs={24} style={colStyle} className={'mt-2'}>*/}
                    {/*    <WidgetWrapper>*/}
                    {/*        <WidgetBox className={'outerCardWrapper'}>*/}
                    {/*            <Calendar apiKey={API_KEY} calendars={calendars}/>*/}
                    {/*        </WidgetBox>*/}
                    {/*    </WidgetWrapper>*/}
                    {/*</Col>*/}
                </Row>

            </>
        )
    }

    renderEUILastMonth = (total_eui) => {
        let color = 'orange', title = 'EUI Last 12 Month (kBtu/sqft-yr)', unit = '$', label = 'Total Cost Breakdown';
        return (
            <>

                <DashBoardGaugeCharts title={'EUI Last 12 Month (kBtu/sqft-yr)'} />

            </>
        )
    }

    onPrint = () => {
        this.child.current.composeManagerPDF('Report', 0);
    }

    handleWorkOrderStatusClick = (e) => {
        const url = '/workorder?status=' + e.type;
        this.props.navigate.push(url);
        this.props.setCurrentRouter(['workorder']);
    }

    handleWorkOrderPriorityClick = (e) => {
        const url = '/workorder?priority=' + e.type;
        this.props.navigate.push(url);
        this.props.setCurrentRouter(['workorder']);
    }

    handleWorkSummaryClick = (e) => {
        console.log('e', e)
        let range = '';
        switch (e.date_range) {
            case 'Past 30 Days':
                range = 30;
                break;
            case 'Past 7 Days':
                range = 7;
                break;
            case 'Past 90 Days':
                range = 90;
                break;

        }
        let params = '';
        switch (e.status) {
            case 'Completed':
                params = 'complete_date=' + range
                break;
            case 'Reported':
                e.type = 'Reported'
                params = 'report_date=' + range
                break;
            case 'Created':
                e.type = 'Created'
                params = 'created_date=' + range
                break;
        }
        const url = '/workorder?' + params;
        this.props.navigate.push(url);
        this.props.setCurrentRouter(['workorder']);
    }

    handleEquipmentCategoryClick = (e) => {
        const url = '/equipment?category=' + e.type;
        this.props.navigate.push(url);
        this.props.setCurrentRouter(['equipment']);
    }

    handleEquipmentConditionClick = (e) => {
        const url = '/equipment?condition=' + e.type;
        this.props.navigate.push(url);
        this.props.setCurrentRouter(['equipment']);
    }

    render() {
        const { rowStyle, colStyle } = basicStyle;
        let facility_data = [];
        for (let i = 0; i < this.props.facility.length; i++) {
            facility_data.push(
                this.props.facility[i].record
            )
        }

        return (
            <LayoutContentWrapper>
                <div id={'Report_0'} style={styles.wisgetPageStyle} className={'m-4'}>
                    {this.renderTopStats()}
                    <Row style={{ margin: '-4px' }}>
                        <PermissionControl allowedPermissions={'1:workorder'}>
                            <Col {...colLayout}>
                                <div style={contentStyle} className='Dashboard_charts_0'>
                                    <PeiChart
                                        data={this.state.groupedStatusData}
                                        height={200}
                                        colors={{
                                            'Completed': 'green',
                                            'Verifying': 'blue',
                                            'On-Hold': 'lightgrey',
                                            'InProgress': 'orange',
                                            'Not Started': 'red'
                                        }}
                                        title={'Workorder by Status'}
                                        handleChartClick={this.handleWorkOrderStatusClick}
                                    />

                                </div>
                            </Col>
                        </PermissionControl>
                        <PermissionControl allowedPermissions={'1:workorder'}>
                            <Col {...colLayout}>
                                <div style={contentStyle} className='Dashboard_charts_1'>

                                    <PeiChart data={this.state.groupedPriorityData}
                                        height={200}
                                        colors={{
                                            'Urgent': 'red',
                                            'Important': 'orange',
                                            'Low': 'green'
                                        }}
                                        handleChartClick={this.handleWorkOrderPriorityClick}
                                        title={'Workorder by Priority'} />
                                </div>
                            </Col>
                        </PermissionControl>
                        <PermissionControl allowedPermissions={'1:workorder'}>
                            <Col {...colLayout}>
                                <div style={contentStyle} className='Dashboard_charts_2'>

                                    <ColumnChart
                                        height={200}
                                        data={this.state.past7_past30}
                                        type={'date_range'}
                                        value={'value'}
                                        seriesField={'status'}
                                        title='Workorder Summary'
                                        slider={false}
                                        isStack={false}
                                        isGroup={true}
                                        handleChartClick={this.handleWorkSummaryClick}
                                        colors={{
                                            Completed: '#52c41a',
                                            Reported: '#1890ff',
                                            Created: '#faad14',
                                        }}
                                    />
                                </div>
                            </Col>
                        </PermissionControl>
                        <PermissionControl allowedPermissions={'1:gas'}>
                            <Col {...colLayout}>
                                <div style={contentStyle} className='Dashboard_charts_3'>
                                    {this.renderEUILastMonth(this.state.total_eui)}
                                </div>
                            </Col>
                        </PermissionControl>
                        <PermissionControl allowedPermissions={'1:equipment'}>

                            <Col {...colLayout}>
                                <div style={contentStyle} className='Dashboard_charts_4'>
                                    <PeiChart data={this.state.equipment_category}
                                        height={200}
                                        handleChartClick={this.handleEquipmentCategoryClick}
                                        title={'Equipment by Category'} />
                                </div>
                            </Col>
                        </PermissionControl>
                        <PermissionControl allowedPermissions={'1:equipment'}>

                            <Col {...colLayout}>
                                <div style={contentStyle} className='Dashboard_charts_5'>
                                    <PeiChart data={this.state.equipment_condition}
                                        height={200}
                                        handleChartClick={this.handleEquipmentConditionClick}
                                        title={'Equipment by Condition'} />
                                </div>
                            </Col>
                        </PermissionControl>

                    </Row>
                </div>
            </LayoutContentWrapper>
        );
    }
}


const mapStateToProps = state => {
    // console.log('props', state)
    return {
        electricity: _.get(state.Electricity, 'data'),
        gas: _.get(state.Gas, 'data'),
        water: _.get(state.Water, 'data'),
        workorder: _.get(state.WorkOrder, 'data'),
        facility_id: _.get(state.SubTask, 'selected.Id'),
        facility: _.get(state.Facility, 'data'),
        facility_selected: _.get(state.Facility, 'selected.value'),
        company_selected: _.get(state.Facility, 'selected.record.company_id'),
        permission: _.get(state.Auth, 'permission'),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setElectricitySuccess: (e) => dispatch(electricity_actions.ElectricitySuccess(e)),
        setGasSuccess: (e) => dispatch(gas_actions.GasSuccess(e)),
        setWorkorderSuccess: (e) => dispatch(workorder_actions.WorkorderSuccess(e)),
        setCurrentRouter: (e) => dispatch(appActions.changeCurrent(e)),
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Overview));

