import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from "antd";

import moment from "moment";
import { api } from "@redux/api";
import _ from "lodash";
import { CurrencyFormat, PercentFormat, TwoDecimalFormat } from "@components/Format";
import {
  NormalizedCalculationsElec,
  NormalizedCalculationsGas,
  StandardizedCalculationsElec,
  StandardizedCalculationsGas
} from "@containers/UtilityData/NormalizedCalculations";
import { useSafeState } from 'ahooks';
import { SavingCharts } from '@/containers/UtilityData/New-Savings/SavingCharts';

export const SavingAnalysisCardIdMappings = {
  NormalizedElectricityConsumptionVsBaselineScatterPlot: {
    id: 'ChartId_NormalizedElectricityConsumptionVsBaselineScatterPlot',
    title: 'Normalized Electricity Consumption Vs Baseline (Scatter Plot)'
  },
  NormalizedGasConsumptionVsBaselineScatterPlot: {
    id: 'ChartId_NormalizedGasConsumptionVsBaselineScatterPlot',
    title: 'Normalized Gas Consumption Vs Baseline (Scatter Plot)'
  },
  MonthlyElectricityConsumptionVsBaselineTimeSeries: {
    id: 'ChartId_MonthlyElectricityConsumptionVsBaselineTimeSeries',
    title: 'Monthly Electricity Consumption Vs Baseline (Time Series)'
  },
  MonthlyGasConsumptionVsBaselineTimeSeries: {
    id: 'ChartId_MonthlyGasConsumptionVsBaselineTimeSeries',
    title: 'Monthly Gas Consumption Vs Baseline (Time Series)'
  },
  MonthlyCostSavings: {
    id: 'ChartId_MonthlyCostSavings',
    title: 'Monthly Cost Savings'
  },
  CumulativeCostSavings: {
    id: 'ChartId_CumulativeCostSavings',
    title: 'Cumulative Cost Savings'
  },
}

const handlePriceTypeCal = (price_type, custom_price, baseline_data, post_data) => {
  let average_price = 0;
  let sum_cost = 0;
  let sum_usage = 0;
  if (price_type === 'Baseline Average') {
    baseline_data.forEach(e => {
      sum_cost += e["costs"] * 1
      sum_usage += e['usage'] * 1
    })
    average_price = sum_cost / sum_usage
  } else if (price_type === 'Post-Period Average') {
    post_data.forEach(e => {
      sum_cost += e["costs"] * 1
      sum_usage += e['usage'] * 1
    })
    average_price = sum_cost / sum_usage
  } else {
    average_price = parseFloat(custom_price);
  }
  console.log('price_type, average_price: ', price_type, average_price)
  return { price_type, average_price };
}

export const SavingAnalysisChart = ({ savingAnalysis: props }) => {

  const Area = useSelector((state) => state.Facility.selected.record.area);

  const [state, setSafeState] = useSafeState({
    GasMonthlyUsage: [],
    ElecMonthlyUsage: [],
    GasSelected: [],
    ElecSelected: [],
    GasR_Squared: null,
    ElecR_Squared: null,
    GasCumulativeUsage: [],
    ElecCumulativeUsage: [],
    ElecData: [],
    GasData: [],
    GasCv: null,
    ElecCv: null,
    ElecReg: [],
    ElecUsage: [],
    ElecPostDate: [],

    GasReg: [],
    GasUsage: [],
    GasPostDate: [],
    ElecSummaryData: [],
    GasSummaryData: [],
  })

  const setState = (newState) => {
    setSafeState((oldData) => ({
      ...oldData,
      ...newState
    }))
  }

  useEffect(() => {
    render()
  }, [])

  const handleData = (combined_result, dataset_baseline, dataset_post, usage, type, price, type_key, Post_Date) => {
    let summary_data = []
    let baseline_result = _.get(combined_result, 'baseline_result');
    let post_result = _.get(combined_result, 'post_result');
    console.log('combined_result', dataset_baseline, dataset_post)

    let lr_baseline = _.get(baseline_result, 'data');
    let lr_post = _.get(post_result, 'data');
    let lr_data = [];
    for (let i = 0; i < lr_baseline.x.length; i++) {
      lr_data.push({
        x: lr_baseline.x[i],
        y: lr_baseline.y[i],
        type: 'Baseline',
        date: dataset_baseline[i]['service_month'].substring(0, 7)
      })
    }
    for (let i = 0; i < lr_post.x.length; i++) {
      lr_data.push({
        x: lr_post.x[i],
        y: lr_post.y[i],
        type: 'Post Period',
        date: dataset_post[i]['service_month'].substring(0, 7)
      })
    }

    let regression_data = [];
    for (let i = 0; i < lr_baseline.xHat.length; i++) {
      regression_data.push([lr_baseline.xHat[i], lr_baseline.yHat[i]])
    }

    let usage_data = [];

    usage.map((e, index) => {
      let dateDiff = e['service_date']
      let u = (type === 'Gas' ? StandardizedCalculationsGas(lr_post.yPredicted[index], dateDiff, Area) :
        StandardizedCalculationsElec(lr_post.yPredicted[index], dateDiff, Area));
      console.log('type_key', type_key, u)
      usage_data.push({
        date: moment(e['service_month']).format('YYYY-MM'),
        value: u,
        type: 'Baseline',
        area: Area,
        service_date: e['service_date']
      })


    })

    usage.map((e, index) => {
      usage_data.push({
        date: moment(e['service_month']).format('YYYY-MM'),
        value: e['Usage'] + e['Adjustment'],
        type: 'Post Period',
        area: Area,
        service_date: e['service_date']
      })
    })

    // Cumulative Cost Savings
    let saved_usage = [], saved_sum = [], monthly_saved_usage = [];

    usage.map((e, index) => {

      let dateDiff = e['service_date']
      // let base = lr_post.yPredicted[index] * Area * dateDiff / 1000 * (type === 'Electricity' ? 24 : 1);
      let base = (type === 'Gas' ?
        StandardizedCalculationsGas(lr_post.yPredicted[index], dateDiff, Area) :
        StandardizedCalculationsElec(lr_post.yPredicted[index], dateDiff, Area));
      let adjust = e['Usage'] + e['Adjustment'];
      let saved = (base - adjust) * price;

      saved_sum.push(saved)
      saved_usage.push({
        date: moment(e['service_month']).format('YYYY-MM'),
        value: saved_sum.reduce((a, b) => a + b, 0),
        type: type
      })

      monthly_saved_usage.push({
        date: moment(e['service_month']).format('YYYY-MM'),
        value: saved?.toFixed(2) * 1,
        type: type
      })

      console.log('saved', e)
      summary_data.push({
        'ServiceStart': e['service_start_date'],
        'ServiceEnd': e['end_start_date'],
        'Usage': e['Usage'],
        'Adjustment': e['Adjustment'],
        'ServiceMonth': moment(e['service_month']).format('YYYY-MM'),
        'AvgOAT': TwoDecimalFormat(e['AvgOAT']),
        'Area': Area,
        'ServiceDays': dateDiff,
        'AdjustUsage': TwoDecimalFormat(adjust),
        'NormUsage': TwoDecimalFormat(adjust * 1000 / dateDiff / Area * (type === 'Electricity' ? 24 : 1)),
        'ModelUsage': TwoDecimalFormat(lr_post.yPredicted[index]),

        'BasicUsage': TwoDecimalFormat(base),
        'SavedUsage': TwoDecimalFormat(base - adjust),
        'Savings': PercentFormat((base - adjust) / base),
        'CostSavings': CurrencyFormat(saved),
        'CumulativeSavings': CurrencyFormat(saved_sum.reduce((a, b) => a + b, 0))
      })

    })
    setState({
      [type_key + 'Data']: lr_data,
      [type_key + 'Reg']: regression_data,
      [type_key + 'Usage']: usage_data,
      [type_key + 'CumulativeUsage']: saved_usage,
      [type_key + 'R_Squared']: lr_baseline.r_squared,
      [type_key + 'Cv']: lr_baseline.cv,
      [type_key + 'PostDate']: Post_Date,
      [type_key + 'SummaryData']: summary_data,
      [type_key + 'MonthlyUsage']: monthly_saved_usage,
      isLoading: false
    })
  }

  const Init = (props, Baseline_Date, Post_Date, dataset_baseline, dataset_post, price, type, type_key,
    range0, range1, breakpoint0 = null, breakpoint1 = null, breakpoint2 = null, breakpoint3 = null, n_segments) => {

    console.log('priceprice: ', price)
    // filter data
    // make sure pass the same length of past month
    // console.log('data', dataset_baseline, dataset_post)
    let usage = [], dates = [];

    var x_baseline = dataset_baseline.map(function (d) {
      return parseFloat(d['avgoat']);
    });

    var x_post = dataset_post.map(function (d) {
      return parseFloat(d['avgoat']);
    });

    //construct y axis
    var y_baseline = dataset_baseline.map(function (d) {
      let adj = d['usage'] * 1 + d['adjustment'] * 1;
      let dateDiff = d['service_date']

      let normal =
        (type === 'Gas' ? NormalizedCalculationsGas(adj, dateDiff, Area) :
          NormalizedCalculationsElec(adj, dateDiff, Area));
      return normal
    });

    var y_post = dataset_post.map(function (d) {
      let adj = d['usage'] * 1 + d['adjustment'] * 1;
      let dateDiff = d['service_date']

      let normal = (type === 'Gas' ? NormalizedCalculationsGas(adj, dateDiff, Area) :
        NormalizedCalculationsElec(adj, dateDiff, Area));
      usage.push({
        service_start_date: d['service_start_date'],
        end_start_date: d['end_start_date'],
        service_month: d['service_month'],
        Usage: adj,
        Area: Area,
        Adjustment: d['adjustment'],
        AvgOAT: d['avgoat'],
        service_date: d['service_date']
      })
      dates.push(moment(d['service_month']).format('YYYY-MM'))
      return normal
    });
    if (x_baseline.length > 0) {
      api.linearreg(x_baseline, y_baseline, x_post, y_post,
        range0, range1, breakpoint0, breakpoint1, breakpoint2, breakpoint3, n_segments).then(
          response => {
            let dataSource = response.data;

            return {
              baseline_result: {
                data: dataSource,
                success: true,
              },
              post_result: {
                data: {
                  x: x_post,
                  y: y_post,
                  yPredicted: dataSource.yPredicted
                },
                success: true,
              }
            }
          }
        ).then((data) => {
          handleData(data, dataset_baseline, dataset_post, usage, type, price, type_key, Post_Date)
        }
        ).catch(error => (
          error
        ));
    }
  }

  const handleCombinedCumulative = (providedData) => {

    const allDates = [...new Set(providedData.map(item => item.date))].sort();

    const defaultDataStructure = type => {
      let rollingValue = 0;
      return allDates.map(date => {
        const existingEntry = providedData.find(item => item.date === date && item.type === type);
        if (existingEntry) {
          rollingValue = existingEntry.value;
        }
        return {
          date,
          value: rollingValue,
          type
        };
      });
    };

    const filledElectricityData = defaultDataStructure("Electricity");
    const filledGasData = defaultDataStructure("Gas");

    return [...filledElectricityData, ...filledGasData];
  }

  const render = () => {
    let elec_base_query = null;
    let elec_post_query = null;
    if (moment(props.elec_Baseline_Date[0]).format('YYYY-MM-') !== 'Invalid date') {
      elec_base_query = 'start_month=' + moment(props.elec_Baseline_Date[0]).format('YYYY-MM-') + '01' +
        '&end_month=' + moment(props.elec_Baseline_Date[1]).endOf('month').format('YYYY-MM-DD');
      elec_post_query = 'start_month=' + moment(props.elec_Post_Date[0]).format('YYYY-MM-') + '01' +
        '&end_month=' + moment(props.elec_Post_Date[1]).endOf('month').format('YYYY-MM-DD');
    }

    let gas_base_query = null;
    let gas_post_query = null;
    if (moment(props.gas_Baseline_Date[0]).format('YYYY-MM-') !== 'Invalid date') {
      gas_base_query = 'start_month=' + moment(props.gas_Baseline_Date[0]).format('YYYY-MM-') + '01' +
        '&end_month=' + moment(props.gas_Baseline_Date[1]).endOf('month').format('YYYY-MM-DD');
      gas_post_query = 'start_month=' + moment(props.gas_Post_Date[0]).format('YYYY-MM-') + '01' +
        '&end_month=' + moment(props.gas_Post_Date[1]).endOf('month').format('YYYY-MM-DD');
    }

    Promise.all([
      api.filterElectricity(elec_base_query),
      api.filterElectricity(elec_post_query),
      api.filterGas(gas_base_query),
      api.filterGas(gas_post_query)
    ]).then(
      response => {
        return {
          elec_base: _.orderBy(_.get(response[0], 'data.results'), 'service_month', 'asc'),
          elec_post: _.orderBy(_.get(response[1], 'data.results'), 'service_month', 'asc'),
          gas_base: _.orderBy(_.get(response[2], 'data.results'), 'service_month', 'asc'),
          gas_post: _.orderBy(_.get(response[3], 'data.results'), 'service_month', 'asc'),
        }
      }
    ).then((data) => {
      const elec_base = data.elec_base,
        elec_post = data.elec_post,
        gas_base = data.gas_base,
        gas_post = data.gas_post;
      let elec_Price_Type = handlePriceTypeCal(props.elec_Option, props.elec_Custom_Price, data.elec_base, data.elec_post);
      const elec_Price = elec_Price_Type.average_price;
      let gas_Price_Type = handlePriceTypeCal(props.gas_Option, props.gas_Custom_Price, data.gas_base, data.gas_post);
      const gas_Price = gas_Price_Type.average_price;

      handleRender(
        [null, null],
        gas_base,
        gas_post,
        props.gas_Baseline_Date,
        props.gas_Post_Date,
        gas_Price,
        null,
        null,
        props.gas_breakpoint0,
        props.gas_breakpoint1,
        props.gas_breakpoint2,
        props.gas_breakpoint3,
        props.gas_n_segments,

        elec_base,
        elec_post,
        props.elec_Baseline_Date,
        props.elec_Post_Date,
        elec_Price,
        null,
        null,
        props.elec_breakpoint0,
        props.elec_breakpoint1,
        props.elec_breakpoint2,
        props.elec_breakpoint3,
        props.elec_n_segments,
      )

      // this.setState({
      //     elec_Price,
      //     gas_Price
      // })
    }).catch(error => (
      error
    ))

  }

  const handleRender = (
    start_date,
    gas_base,
    gas_post,
    gas_Baseline_Date,
    gas_Post_Date,
    gas_Price,
    gas_range0,
    gas_range1,
    gas_breakpoint0,
    gas_breakpoint1,
    gas_breakpoint2,
    gas_breakpoint3,
    gas_n_segments,
    elec_base,
    elec_post,
    elec_Baseline_Date,
    elec_Post_Date,
    elec_Price,
    elec_range0,
    elec_range1,
    elec_breakpoint0,
    elec_breakpoint1,
    elec_breakpoint2,
    elec_breakpoint3,
    elec_n_segments
  ) => {
    Init({}, elec_Baseline_Date, elec_Post_Date, elec_base,
      elec_post, elec_Price, 'Electricity', 'Elec',
      elec_range0, elec_range1, elec_breakpoint0, elec_breakpoint1, elec_breakpoint2, elec_breakpoint3, elec_n_segments);
    Init({}, gas_Baseline_Date, gas_Post_Date, gas_base,
      gas_post, gas_Price, 'Gas', 'Gas',
      gas_range0, gas_range1, gas_breakpoint0, gas_breakpoint1, gas_breakpoint2, gas_breakpoint3, gas_n_segments);

  }

  const handleLGSelect = (e, type) => {
    // console.log('scatterPlot select', e, type)
    if (!_.get(e, 'x')) {
      let x = _.get(e, 'date')
      e['x'] = x
    }
    setState({
      [type + 'Selected']: e
    })
  }
  let providedCumulativeData = [...state.ElecCumulativeUsage, ...state.GasCumulativeUsage];
  let cumulative = handleCombinedCumulative(providedCumulativeData);
  let providedMonthlyData = [...state.ElecMonthlyUsage, ...state.GasMonthlyUsage]
  let monthly_usage = providedMonthlyData.sort((a, b) => (a.date > b.date) ? 1 : -1);

  return (
    <>
      <div className='flex justify-center' style={{ color: '#396598', fontWeight: 700, fontSize: 16, margin: 10, marginTop: 40 }}>
        <div style={{ width: 1050 }}>Baseline and Energy Saving analysis</div>
      </div>

      <div className='flex justify-center' style={{ color: '#396598', fontWeight: 700, fontSize: 14, margin: 10 }}>
        <div style={{ width: 1050 }} className='flex'>
          <div className='flex-1'>
            <div className='pdfChart_Elec_Baseline'>
              Electricity Baseline Period: {props.elec_Baseline_Date?.[0]} - {props.elec_Baseline_Date?.[1]}
            </div>
            <div className='pdfChart_Elec_Post'>
              Electricity Post Period: {props.elec_Post_Date?.[0]} - {props.elec_Post_Date?.[1]}
            </div>
            <div className='pdfChart_Elec_Price'>
              Electricity Price: {props.elec_Option === "Custom" ? props.elec_Custom_Price : props.elec_Option}
            </div>
            {
              props.elec_usedModuleValue === 'Segments' ?
                <div className='pdfChart_Elec_Segment'>Electricity Segments: {props.elec_n_segments}</div> :
                <div className='pdfChart_Elec_Segment'>Electricity Breakpoints: {props.elec_breakpoint1},{props.elec_breakpoint2},{props.elec_breakpoint3}</div>
            }

          </div>
          <div style={{ width: 50 }}></div>
          <div className='flex-1'>
            <div className='pdfChart_Gas_Baseline'>
              Gas Baseline Period: {props.gas_Baseline_Date?.[0]} - {props.gas_Baseline_Date?.[1]}
            </div>
            <div className='pdfChart_Gas_Post'>
              Gas Post Period: {props.gas_Post_Date?.[0]} - {props.gas_Post_Date?.[1]}
            </div>
            <div className='pdfChart_Gas_Price'>
              Gas Price: {props.gas_Option === "Custom" ? props.gas_Custom_Price : props.gas_Option}
            </div>
            {
              props.gas_usedModuleValue === 'Segments' ?
                <div className='pdfChart_Gas_Segment'>Gas Segments: {props.gas_n_segments}</div> :
                <div className='pdfChart_Gas_Segment'>Gas Breakpoints: {props.gas_breakpoint1},{props.gas_breakpoint2},{props.gas_breakpoint3}</div>
            }
          </div>
        </div>
      </div>

      <SavingCharts
        NormalizedElec={{
          data: state['ElecData'],
          regression: state.ElecReg,
          r_squared: state.ElecR_Squared,
          cv: state.ElecCv,
        }}
        NormalizedGas={{
          data: state['GasData'],
          regression: state.GasReg,
          r_squared: state.GasR_Squared,
          cv: state.GasCv,
        }}
        MonthlyElec={{
          data: state.ElecUsage,
        }}
        MonthlyGas={{
          data: state.GasUsage,
        }}
        MonthlyCost={{
          data: monthly_usage,
        }}
        CumulativeCost={{
          data: cumulative,
        }}
      />
    </>
  );

}
