import React from "react";
import _ from "lodash";

import moment from "moment";
import { safemomentmax, safemomentmin } from "@/containers/UtilityData/New-Summary/utils";
import useDateRange from "@/hooks/use-date-range";
import {SummaryChart} from "@/containers/UtilityData/New-Summary";

export const PDFSummaryChart = ({ period, endingMonth, rangeMonth }) => {

  let resultPeriod = period
  let resultEndingMonth = moment(endingMonth).format('YYYY-MM')
  let resultDateRange = rangeMonth
  const dateRange = useDateRange()

  if (period === 'custom') {
      resultPeriod = rangeMonth[1].diff(rangeMonth[0], 'month') + 1;
      resultEndingMonth = rangeMonth[1].format('YYYY-MM');
  } else if (period === 'YTD') {
      resultPeriod = moment().diff(moment().startOf('year'), 'month') + 1;
      resultEndingMonth = moment().format('YYYY-MM');
  } else if (period === 'All') {
      const range = [safemomentmin(moment(dateRange.date_range_elec_min), moment(dateRange.date_range_gas_min)), safemomentmax(moment(dateRange.date_range_elec_max), moment(dateRange.date_range_gas_max))]
      resultPeriod = range[1].diff(range[0], 'month') + 1;
      resultEndingMonth = range[1].format('YYYY-MM');
      resultDateRange = range;
  }

  return <SummaryChart resultPeriod={resultPeriod} resultEndingMonth={resultEndingMonth} />
};
