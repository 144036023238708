import { Drawer, Space, Form } from "antd";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useBoolean, useSafeState } from "ahooks";
import { CloseOutlined } from "@ant-design/icons";
import { PdfProvider, usePdfContext } from "./context";
import PdfTable from "./PdfTable";
import PdfForm, { savingAnalysisOptions } from './PdfForm'
import PdfLoading from './PdfLoading'
import { ReviewPdf } from './ReviewPdf'
import moment from "moment";
import { useSelector } from "react-redux";
import { summaryChartClassnames } from "../UtilityData/New-Summary/components/summaryChartClassnames";
import { safemomentmax, safemomentmin } from "../UtilityData/New-Summary/utils";
import useDateRange from "@/hooks/use-date-range";
import { savingsChartClassnames } from "@/containers/UtilityData/New-Savings/components/savingsChartClassnames";

export const PdFStepMapping = {
  PDFForm: 0,
  PDFLoading: 1,
  PDFView: 2
}

const PdfReport = () => {
  const { pdfStep, isDrawerVisible, closeDrawer } = usePdfContext();

  const viewPDfStep = useMemo(() => {
    switch (pdfStep) {
      case PdFStepMapping.PDFForm:
        return <PdfForm />;
      case PdFStepMapping.PDFLoading:
        return <PdfLoading />;
      case PdFStepMapping.PDFView:
        return <ReviewPdf />;
      default:
        return <PdfForm />;
    }
  }, [pdfStep])

  return (
    <div style={{ padding: 10 }}>
      <Drawer
        title="Report Builder"
        extra={
          <Space>
            <CloseOutlined onClick={closeDrawer} />
          </Space>
        }
        open={isDrawerVisible}
        placement="right"
        width='100%'
        closable={true}
        onClose={closeDrawer}
      >
        {viewPDfStep}
      </Drawer>
      <PdfTable />
    </div>
  );
};

const getInitialValues = ({ facilityName, dateRange }) => {

  const elecMaxDate = moment(dateRange.date_range_elec_max)
  const gasMaxDate = moment(dateRange.date_range_gas_max)
  const elecMinDate = moment(dateRange.date_range_elec_min)
  const gasMinDate = moment(dateRange.date_range_gas_min)

  const maxDate = safemomentmax(elecMaxDate, gasMaxDate)
  const minDate = safemomentmin(elecMinDate, gasMinDate)

  return {
    name: `${facilityName} - FacilityKPI report ${moment().format("YYYY_MM_DD HH_mm")}`,
    utilityVisible: true,
    dashboardVisible: true,
    periodItem: summaryChartClassnames.map((item) => item.id),
    period: '12',
    endingMonth: maxDate.clone(),
    rangeMonth: [maxDate.clone().subtract(1, 'year'), maxDate.clone()],
    allRangeMonth: [minDate.clone(), maxDate.clone()],
    savingAnalysisItem: savingsChartClassnames.map(item => item.id),
    savingAnalysis: {
      elec_Option: "Baseline Average",
      gas_Option: "Baseline Average",
      elec_Baseline_Date: [
        elecMaxDate.clone().add(-23, 'month').format("YYYY-MM"),
        elecMaxDate.clone().add(-12, 'month').format("YYYY-MM")
      ],
      elec_Post_Date: [
        elecMaxDate.clone().add(-11, 'month').format("YYYY-MM"),
        elecMaxDate.clone().format("YYYY-MM")
      ],
      gas_Baseline_Date: [
        gasMaxDate.clone().add(-23, 'month').format("YYYY-MM"),
        gasMaxDate.clone().add(-12, 'month').format("YYYY-MM")
      ],
      gas_Post_Date: [
        gasMaxDate.clone().add(-11, 'month').format("YYYY-MM"),
        gasMaxDate.clone().format("YYYY-MM")
      ],
      gas_usedModuleValue: "Segments",
      elec_usedModuleValue: "Segments",
      gas_n_segments: 2,
      elec_n_segments: 2,
      breakpoints: [],
    }
  }
}

const WithContextPdfReport = () => {

  const tableActionRef = useRef()
  const reloadTable = useCallback(() => {
    if (typeof tableActionRef.current?.reload === 'function') {
      tableActionRef.current.reload();
    }
  }, [])

  const [isDrawerVisible, { setTrue: openDrawer, setFalse: closeDrawer }] =
    useBoolean(false);

  const [pdfFormValue, setPdfFormValue] = useSafeState({})
  const [pdfImages, setPdfImages] = useSafeState({})
  const [pdfStep, setPdfStep] = useState(PdFStepMapping.PDFForm)
  const facilityName = useSelector((state) => state?.Facility?.selected?.value ?? '')
  const [form] = Form.useForm();
  const dateRange = useDateRange()

  const initForm = (values) => {

    form.setFieldsValue(values ? values : getInitialValues({ facilityName, dateRange }))
  }

  const goToFormStep = () => {
    setPdfStep(PdFStepMapping.PDFForm)
  }

  const goToLoadingStep = () => {
    setPdfStep(PdFStepMapping.PDFLoading)
  }

  const goToViewStep = () => {
    setPdfStep(PdFStepMapping.PDFView)
  }

  return (
    <PdfProvider
      value={{
        isDrawerVisible,
        openDrawer,
        closeDrawer,
        pdfFormValue,
        setPdfFormValue,
        pdfStep,
        goToFormStep,
        goToLoadingStep,
        goToViewStep,
        pdfImages,
        setPdfImages,
        reloadTable,
        tableActionRef,
        form,
        initForm
      }}
    >
      <PdfReport />
    </PdfProvider>
  );
};

export default React.memo(WithContextPdfReport);

