import React from 'react';
import EChartsReact from '@components/EChartsReact';
import LayoutContent from "@components/utility/layoutContent";
import _ from "lodash";

const ColumnChart = (props) => {
    const {
        data,
        height,
        isStack,
        type,
        value,
        seriesField,
        colors,
        title,
        handleChartClick
    } = props;

    // 获取所有唯一的系列名称
    const seriesNames = [...new Set(data.map(item => item[seriesField]))];

    const option = {
        backgroundColor: '#fff',
        title: {
            text: title,
            left: 'center',
            top: '2%',
            textStyle: {
                color: '#396598',
                fontSize: 14
            }
        },
        legend: {
            data: seriesNames,
            left: 'center',
            top: '10%'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: {
            type: 'category',
            data: [...new Set(data.map(item => item[type]))],
            axisLabel: {
                interval: 0,
                // rotate: 30
            }
        },
        yAxis: {
            type: 'value'
        },
        series: seriesNames.map(name => ({
            name: name,
            type: 'bar',
            stack: isStack ? 'total' : undefined,
            data: data
                .filter(item => item[seriesField] === name)
                .map(item => item[value]),
            itemStyle: {
                color: colors?.[name] || undefined
            }
        })),
        grid: {
            top: '20%',
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
        }
    };

    const onEvents = {
        'click': (params) => {
            const clickedData = data.find(item =>
                item[type] === params.name &&
                item[seriesField] === params.seriesName
            );
            if (clickedData && handleChartClick) {
                handleChartClick(clickedData);
            }
        }
    };

    return (
        <EChartsReact
            option={option}
            style={{ height: '100%', width: '100%' }}
            onEvents={onEvents}
        />
    );
};

export default ColumnChart;
