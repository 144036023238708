
export const setFacilityCache = (userId, facilityId) => {
    if(userId && facilityId) {
        window.localStorage.setItem('facilityCache-' + userId, facilityId)
    }
}

export const getFacilityCache = (userId) => {
    return window.localStorage.getItem('facilityCache-' + userId)
}
