import React, { useMemo } from 'react';
import EChartsReact from "@/components/EChartsReact"
import { useWeatherData } from '../hooks/useSummaryData';
import { groupBy } from 'lodash'
import moment from 'moment';
import { NormalizedCalculationsElec, NormalizedCalculationsGas } from '../../NormalizedCalculations';
import { TwoDecimalFormat } from '@/components/Format';
import { useSelector } from 'react-redux';
import { ClassName_NORMALIZED_ELECTRICITY_USAGE_OAT, ClassName_NORMALIZED_GAS_USAGE_OAT } from './summaryChartClassnames';

const formatChartData = (data = [], type, area) => {
  return data.map(d => {
    let a = new Date(d['end_start_date']);
    let b = new Date(d['service_start_date']);
    let dateDiff = (a.getTime() - b.getTime()) / (1000 * 3600 * 24) + 1

    let yField = type === 'electricity' ? NormalizedCalculationsElec(
      Number((d["usage"]) + Number(d["adjustment"])),
      dateDiff,
      area
    ) :
      NormalizedCalculationsGas(
        Number((d["usage"]) + Number(d["adjustment"])),
        dateDiff,
        area
      )

    return {
      'Month': moment(d['service_month']).format('YY-MMM'),
      WattSqft: TwoDecimalFormat(yField) * 1,
      'MonthlyAvgMeanOAT': d["avgoat"]
    }
  })
}

export const config = {
  gas: {
    title: 'Normalized Gas Usage Vs. OAT',
    name: 'Gas',
    unit: 'But/hr-sqft'
  },
  electricity: {
    title: 'Normalized Electricity Usage Vs. OAT',
    name: 'Electricity',
    unit: 'Watt/sqft'
  }
}

const colors = [
  '#A52A2A',  // 深棕色
  '#ADD8E6',  // 浅蓝色
  '#33FF57',  // 明亮的绿色
  '#FF33A1',  // 明亮的粉红色
  '#F1C40F'   // 明亮的黄色
];

export const NormalizedWeatherCharts = ({ type, period, endingMonth }) => {
  const Area = useSelector(state => state?.Facility?.selected?.record?.area)

  const { data } = useWeatherData(type, period, endingMonth)

  const chartData = formatChartData(data, type, Area)

  const groupByYear = groupBy(chartData, (item) => '20' + item.Month.split('-')[0])

  const classname = type === 'gas' ? ClassName_NORMALIZED_GAS_USAGE_OAT : ClassName_NORMALIZED_ELECTRICITY_USAGE_OAT;

  const option = {
    backgroundColor: '#fff',
    tooltip: {
      trigger: 'item',
    },
    xAxis: {
      type: 'value',
      name: 'Monthly Mean OAT (deg F)',
      nameLocation: 'middle',
      nameGap: 30,
      nameTextStyle: {
        fontWeight: 'bold'
      },
      min: chartData.length > 0 ? function (value) { return value.min - 10; } : 30,
      max: chartData.length > 0 ? function (value) { return value.max + 10; } : 100,
      splitLine: {
        show: false
      },
      axisLabel: {
        formatter: function (value) {
          return parseInt(value);
        }
      }
    },
    yAxis: {
      type: 'value',
      name: config[type].unit,
      nameLocation: 'middle',
      nameGap: 40,
      nameTextStyle: {
        fontWeight: 'bold'
      },
      min: chartData.length > 0 ? function (value) { return value.min - 0.1 } : 2,
      max: chartData.length > 0 ? function (value) { return value.max + 0.1 } : 3,
      axisLabel: {
        formatter: function (value) {
          return parseFloat(value).toFixed(2);
        }
      }
    },
    series: Object.keys(groupByYear).map(year => {
      return {
        name: year,
        type: 'scatter',
        itemStyle: {
          color: colors[year % colors.length]
        },
        data: groupByYear[year].map(item => ({
          date: item.Month,
          name: item.Month.split('-')[0],
          value: [item.MonthlyAvgMeanOAT, item.WattSqft]
        })),
      }
    }),
    title: {
      text: config[type].title,
      left: 'center',
      top: '2%',
      textStyle: {
        color: '#396598',
        fontSize: 14
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return `<span style="background:${params.color};width:10px;height:10px;display:inline-block;border-radius:50%;margin-right:10px"></span>${'20' + params.data.date} <br/> Watt/Sqft: ${params.data.value[1]} <br/> OAT: ${params.data.value[0]}`;
      },
    },
    legend: {
      data: Object.keys(groupByYear),
      left: 'center',
      top: '12%',
      itemWidth: 10,
      itemHeight: 10
    },
    grid: {
      top: '20%',
      left: '6%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
  };

  return (
    <EChartsReact key={JSON.stringify(option)} className={classname} option={option} style={{ width: '100%', height: '100%' }} />
  );
}