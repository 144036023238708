import { PdfKeyStatistics } from "@/containers/UtilityData/New-Summary/components/KeyStatistics";
import { PDFPageContainer } from "../components/PageContainer";
import { PdfTitle } from "../components/title";
import { col_2, convertTo2DArray, fullHeight, fullWidth, pdfFontSize } from "./utils";
import React from "react";
import { ClassName_EUIkBtuPersqftyr, ClassName_EUIBreakdownkBtuPersqftyr } from "@/containers/UtilityData/New-Summary/components/summaryChartClassnames";

const PDFSummary = (_, { pdfFormValue, pdfImages }) => {
  console.log('pdfImages', pdfImages)
  let summary = pdfImages.summary;

  if (summary.length === 0) {
    return null
  }

  const pieChartsNames = [ClassName_EUIBreakdownkBtuPersqftyr, ClassName_EUIkBtuPersqftyr]

  const keyStatistics = summary.find(item => item?.id === 'ChartsClassName-KeyStatistics')

  const ClassName_EUIBreakdownkBtuPersqftyrChart = summary.find(item => item?.id === ClassName_EUIBreakdownkBtuPersqftyr)
  const ClassName_EUIkBtuPersqftyrChart = summary.find(item => item?.id === ClassName_EUIkBtuPersqftyr)

  const pieCharts = summary.filter(item => pieChartsNames.includes(item?.id))
  if (keyStatistics) {
    summary = summary.filter(item => !['ChartsClassName-KeyStatistics', ...pieChartsNames].includes(item?.id))
  }

  const images2d = convertTo2DArray(summary)
  return (
    <PDFPageContainer lastPage={true}>
      <PdfTitle w={140} title="Utility Summary" subTitle={pdfImages.chartId_SummaryTitle}></PdfTitle>
      <p-stack margin={[0, 5, 0, 5]}>
        <p-text></p-text>
      </p-stack>
      {
        images2d.map((imgs, index) => {
          return (
            <p-col columnGap={40} key={index}>
              {
                imgs.map((img, idx) => {
                  if (!img) {
                    return <p-text></p-text>
                  } else {
                    return (
                      <p-img key={idx} dw={400} dh={250} width={250} src={img.img}></p-img>
                    );
                  }
                })
              }
            </p-col>
          )
        })
      }
      <p-col columnGap={140}>
        {ClassName_EUIBreakdownkBtuPersqftyrChart && <p-img dw={400} dh={250} width={120} src={ClassName_EUIBreakdownkBtuPersqftyrChart.img}></p-img>}
        {ClassName_EUIkBtuPersqftyrChart && <p-img dw={400} dh={250} width={200} src={ClassName_EUIkBtuPersqftyrChart.img}></p-img>}
      </p-col>
      {
        keyStatistics && (
          <PdfKeyStatistics {...keyStatistics.props} />
        )
      }

    </PDFPageContainer>

  );
};

export default PDFSummary;
