import { Upload, Form } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const AvatarUploadWrapper = styled.div`
  .ant-upload-list-item {
    border: 0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before,
  .ant-upload-list-item-info,
  .ant-upload.ant-upload-select-picture-card
   {
    border-radius: 50%;
  }
  /* .ant-upload-list-item-info {
    border-radius: 50%;
  }
  .ant-upload.ant-upload-select-picture-card {
    border-radius: 50%;
  } */
`

export const AvatarUpload = ({ disabled, defaultImage }) => {
  const form = Form.useFormInstance();
  
  const [fileList, setFileList] = useState(() => {
    return defaultImage ? [
      {
        uid: '-1',
        name: 'default-image.png',
        status: 'done',
        url: defaultImage,
      }
    ] : []
  })

  const onChange = ({ fileList: newFileList }) => {
    // 只保留最新的一个文件
    const latestFile = newFileList.length ? [newFileList[newFileList.length - 1]] : [];
    const updatedFileList = latestFile.map(file => {
      if (file.status === 'uploading') {
        return { ...file, status: 'done' };
      }
      return file;
    });
    setFileList(updatedFileList);

    if (updatedFileList.length > 0) {
      form.setFieldsValue({
        avatar: latestFile[0]
      })
    } else {
      form.setFieldsValue({
        avatar: null
      })
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const imgWindow = window.open(src);
    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    }
  };

  let resultFileList = fileList;

  return (
    <AvatarUploadWrapper>
      <ImgCrop shape="round" rotate modalTitle='Upload Avatar'>
        <Upload
          disabled={disabled}
          className="avatar-uploader"
          listType="picture-card"
          fileList={resultFileList}
          onChange={onChange}
          onPreview={onPreview}
          customRequest={({ onSuccess }) => {
            setTimeout(() => {
              onSuccess("ok", null);
            }, 0);
          }}
        >
          {resultFileList.length === 0 && '+ Upload'}
        </Upload>
      </ImgCrop>
    </AvatarUploadWrapper>
  );
};
