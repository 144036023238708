import React from "react"
import { PDFSummaryChart } from '../template/utility/pdf-summary-chart';
import { SavingAnalysisChart } from '../template/utility/savingAnalysis-chart'
import { DashboardChart } from '../template/dashboard/dashboard-chart'
const PreViewObject = {
  period: PDFSummaryChart,
  savingAnalysis: SavingAnalysisChart,
  dashboard: DashboardChart,
}

const PreView = ({type, data}) => {

  const Comp = PreViewObject[type]
  if(!Comp) {
    return null
  }
  return <Comp {...data}/>
}

export default PreView;