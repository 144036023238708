import React, { useState, useMemo } from "react";
import { useRole } from "@/hooks/use-role";
import { useRequest } from "ahooks";
import { Form, Popover, Select, Table, Tag } from "antd";
import { api } from "@/redux/api";
import { InfoCircleOutlined } from "@ant-design/icons";

const formFieldsMapping = {
    'comment': 'Comment',
    'electricity': 'Electricity',
    'gas': 'Gas',
    'company': 'Company',
    'facility': 'Facility',
    'equipment': 'Equipment',
    'workorder': 'Workorder',
    'equipmentCategory': 'EquipmentCategory',
    'user': 'User',
    'benchmark': 'Benchmark',
    'customreport': 'CustomReport',
    'filemanage': 'Filemanage',
    'tags': 'Tags',
    'functionform': 'Functional Form',
    'functiontesting': 'Functional Testing',
    'log': 'Log'
}
const TagConfig = {
    0: {
        color: 'red',
        text: 'Disabled'
    },
    1: {
        color: 'orange',
        text: 'Read Only'
    },
    2: {
        color: 'blue',
        text: 'Editable'
    },
    3: {
        color: 'green',
        text: 'Full Access'
    }
}

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Permission',
        dataIndex: 'permission',
        ellipsis: true,
        tooltip: true,
        render(permission) {
            if (typeof permission === 'number') {
                return <Tag color={TagConfig[permission].color}>{TagConfig[permission].text}</Tag>
            } else {
                return permission
            }
        }
    }
]


export const PermissionProfileFormItem = () => {

    const [searchKeyword, setSearchKeyword] = useState('')

    const RoleKey = useRole();
    const role = Form.useWatch('role')
    const profileValue = Form.useWatch('profile')
    const profile = profileValue?.value ?? profileValue

    const { data: options = [], loading } = useRequest(Lookup_CommonupRequest, {
        defaultParams: ['', RoleKey],
    })

    const currentProfile = options.find(option => option.value === profile)?.record ?? {};
    const dataSource = Object.entries(formFieldsMapping).map(([key, value]) => ({
        name: value,
        permission: currentProfile[key]
    }))

    const content = <div style={{ width: '60vw' }}>
        <Table columns={columns} dataSource={dataSource} pagination={false} size="small" />
    </div>

    const label = <div>
        Permission Profile
        <Popover key={profile} content={content} title="" placement={'right'}>
            <InfoCircleOutlined style={{ marginLeft: 5, fontSize: 14 }} />
        </Popover>
    </div>

    const filterOptions = useMemo(() => {

        const needShow = (role, optionRole) => {
            if (role === 2.2) {
                return optionRole === 2.2
            } else {
                return optionRole >= role
            }
        }

        return options.filter(option => {
            const isShow = needShow(role, option.record.role)
            if (searchKeyword) {
                return isShow && option.label.toLowerCase().includes(searchKeyword.toLowerCase())
            }
            return isShow
        })
    }, [options, role, searchKeyword])


    return <>
        <Form.Item
            label={label}
            name="profile"
            rules={[{ required: true, message: 'Please select permission profile!' }]}
        >
            <Select
                showSearch
                style={{ width: '100%' }}
                options={filterOptions}
                searchValue={searchKeyword}
                onSearch={setSearchKeyword}
                filterOption={false}
                loading={loading}
                allowClear
                notFoundContent={loading ? 'Loading...' : 'No results found'}
            />
        </Form.Item>
    </>;
}

async function Lookup_CommonupRequest(name, role) {
    let query = 'name=' + name + '&role=' + role;
    return api.filterUserPermissions(query).then(
        response => {
            console.log('response', response)
            let data = response?.data?.results;
            if (Array.isArray(data)) {
                return data.map(item => ({ label: item.name, value: item.id, record: item }));
            }
            return [];
        }
    ).catch(error => []);
}

