import { html } from "react-jsx2pdf";
import { PDFPageContainer } from "../components/PageContainer";
import { col_2, convertTo2DArray } from "./utils";
import React from "react";
import { PdfTitle } from "../components/title";

export const PDFDashboardCards = (_, { pdfFormValue, pdfImages }) => {

  const dashboardCard = pdfImages.dashboardCard;
  if (dashboardCard.length === 0) {
    return null
  }

  const images2d = convertTo2DArray(dashboardCard)
  return (
    <PDFPageContainer lastPage={true}>
      <PdfTitle w={88} title="Summary"></PdfTitle>
      {
        images2d.map(imgs => {
          return (
            <p-col columnGap={40} margin={[0,10,0,10]}>
              {
                imgs.map(img => {
                  if (!img) return <p-text></p-text>
                  return (
                    <p-stack width={col_2} unbreakable={true}>
                      <p-img dw={400} dh={250} width={250} src={img.img}></p-img>
                    </p-stack>
                  );
                })
              }
            </p-col>
          );
        })
      }
    </PDFPageContainer>
  );
};

