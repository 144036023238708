import React from "react";
import { parseElement, html } from "react-jsx2pdf";

import HomePage from "./homePage";
import PDFSavings from "./pdf-savings";
import PDFSummary from "./pdf-summary";
import { PDFDashboardCards } from "./pdf-dashboard-cards";
import { PDFDashboardCharts } from "./pdf-dashboard-charts";

const header = (currentPage) => {
  if (currentPage === 1) {
    return null;
  }
  return parseElement(
    <p-svg>
      {html`
        <svg width="60" height="30" xmlns="http://www.w3.org/2000/svg">
          <rect width="100%" height="100%" fill="#a4063e" />
          <text
            x="50%"
            y="50%"
            text-anchor="middle"
            dominant-baseline="middle"
            font-family="sans-serif"
            font-size="18"
            fill="white"
          >
            ${currentPage - 1}
          </text>
        </svg>
      `}
    </p-svg>
  );
};

const footer = (currentPage) => {
  if (currentPage === 1) {
    return null;
  }

  return parseElement(
    <p-stack absolutePosition={{ x: 0, y: 22  }} alignment="center">
      <p-canvas absolutePosition={{ x: 0, y: 20 }}>
        <p-rect x={0} y={0} w={595} h={20} color="#000"></p-rect>
      </p-canvas>
      <p-link
        src="http://www.facilitykpi.com"
        textDecoration="underline"
      >
        <p-text color="#fff" textDecoration="underline">www.facilitykpi.com</p-text>
      </p-link>
    </p-stack>
  );
};

export const Document = () => (
  <p-document header={header} footer={footer} pageMargins={[40,40,40,40]}>
    <HomePage />
    <PDFDashboardCards />
    <PDFDashboardCharts />
    <PDFSummary />
    <PDFSavings />
  </p-document>
);
