import React, { useState } from 'react';
import { Input, Form, Button, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { update_profile } from '@/redux/api';
import { UserProfileAvatar } from './UserProfileAvatar';
import AuthActions from '@/redux/auth/actions';

const UserProfileForm = () => {

    const Auth = useSelector(state => state.Auth);
    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const [isEdit, setIsEdit] = useState(false);

    const onEdit = () => {
        form.setFieldsValue({
            ...Auth,
            username: Auth.user
        });
        setIsEdit(true);
    }

    const onCancel = () => {
        form.setFieldsValue({})
        setIsEdit(false);
    }

    const onSave = async () => {
        form.validateFields().then(values => {
            const { username, email, first_name, last_name } = values;
            update_profile(Auth.userId, {
                username,
                email,
                first_name,
                last_name
            }, true).then(res => {
                if(res.status === 200) {
                    const data = res.data;

                    const {username, email, first_name, last_name} = data;

                    dispatch(AuthActions.userUpdate({
                        name: username,
                        email: email,
                        first_name: first_name,
                        last_name: last_name
                    }));
                }
                onCancel()
            })
        })
    }


    return (

        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0, top: -70, zIndex: 10 }}>
                {isEdit ? <Space>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type='primary' onClick={onSave}>Save</Button>
                </Space> : <Button type='primary' onClick={onEdit}>Edit</Button>}
            </div>
            <div style={{ position: 'absolute', left: 0, top: -280, zIndex: 10, borderRadius: '100%' }}>
                <UserProfileAvatar />
            </div>
            <Form form={form}>
                <Form.Item label="User Name" name="username" rules={[{ required: true, message: 'Please enter your user name' }]}>
                    {/* {isEdit ? <Input /> : Auth.user} */}
                    {Auth.user}
                </Form.Item>
                <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'Please enter a valid email address' }]}>
                    {isEdit ? <Input /> : Auth.email}
                </Form.Item>
                <Form.Item label="First Name" name="first_name" rules={[{ required: true, message: 'Please enter your first name' }]}>
                    {isEdit ? <Input /> : Auth.first_name}
                </Form.Item>
                <Form.Item label="Last Name" name="last_name" rules={[{ required: true, message: 'Please enter your last name' }]}>
                    {isEdit ? <Input /> : Auth.last_name}
                </Form.Item>
            </Form>


        </div>


    )
}

export default UserProfileForm;