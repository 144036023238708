import React from 'react';
import { Row, Col } from 'antd';
import { NormalizedUsageChart } from './components/NormalizedUsageChart';
import { MonthlyUsageBaselineChart } from './components/MonthlyUsageBaselineChart';
import { MonthlyCostSavingsChart } from './components/MonthlyCostSavingsChart';
import { CumulativeCostSavingsChart } from './components/CumulativeCostSavingsChart';

const contentStyle = {
    height: '100%',
    background: '#fff',
    borderRadius: 10,
    border: '1px solid #f0f0f0',
    aspectRatio: '2 / 1'
}

const colLayout = {
    xs: 24,
    lg: 12,
    xxl: 8,
    style: { padding: '4px' }
}

export const SavingCharts = ({
    NormalizedElec,
    NormalizedGas,
    MonthlyElec,
    MonthlyGas,
    MonthlyCost,
    CumulativeCost
}) => {
    return (
        <Row style={{ margin: '-4px' }}>
            <Col {...colLayout}>
                <div style={contentStyle}>
                <NormalizedUsageChart type='elec' {...NormalizedElec} />

                </div>
            </Col>
            <Col {...colLayout}>
                <div style={contentStyle}>
                <NormalizedUsageChart type='gas' {...NormalizedGas} />
                </div>
            </Col>
            <Col {...colLayout}>
                <div style={contentStyle}>
                    <MonthlyCostSavingsChart {...MonthlyCost} />
                </div>
            </Col>
            <Col {...colLayout}>
                <div style={contentStyle}>
                    <MonthlyUsageBaselineChart type='elec' {...MonthlyElec}/>
                </div>
            </Col>
            <Col {...colLayout}>
                <div style={contentStyle}>
                    <MonthlyUsageBaselineChart type='gas' {...MonthlyGas} />
                </div>
            </Col>
            <Col {...colLayout}>
                <div style={contentStyle}>
                    <CumulativeCostSavingsChart {...CumulativeCost} />
                </div>
            </Col>
        </Row>
    );
}
