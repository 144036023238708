import React, { useState } from "react";
import {
  Checkbox,
  Form,
  DatePicker,
  Modal,
  Tooltip,
  message,
  Button,
  Row,
  Col,
  Input,
  Select
} from "antd";
import { Visible } from "@/components/ui";
import { useBoolean, useControllableValue, useUpdate } from "ahooks";
import PreView from "../preView";
import { FundViewOutlined } from "@ant-design/icons";
import { MenuActionsWrap } from "../components/MenuActions";
import { usePdfContext } from "../context";
import { SavingAnalysisForm } from "./SavingAnalysisForm";
import { SavingAnalysisCardIdMappings } from "../template/utility/savingAnalysis-chart";
import { ScenarioForm } from "./ScenarioForm";
import styled from "styled-components";
import moment from "moment";
import { summaryChartClassnames } from "@/containers/UtilityData/New-Summary/components/summaryChartClassnames";
import { safemomentmax, safemomentmin } from "@/containers/UtilityData/New-Summary/utils";
import useDateRange from "@/hooks/use-date-range";
import { savingsChartClassnames } from "@/containers/UtilityData/New-Savings/components/savingsChartClassnames";

const CardItem = styled.div`
  background-color: #f8faff;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 
              -1px -1px 3px rgba(255, 255, 255, 0.5);
`

const CardWrap = styled.div`
  padding: 12px;
`

const { RangePicker } = DatePicker;

const PdfForm = () => {
  const [open, { setTrue: openModal, setFalse: closeModal }] =
    useBoolean(false);

  const update = useUpdate();


  const [modalData, setModalData] = useState({
    type: "",
    data: {},
    title: ''
  });

  const { setPdfFormValue, goToLoadingStep, form } = usePdfContext();
  const period = Form.useWatch('period', form)
  const utilityVisible = Form.useWatch('utilityVisible', form)
  const dateRange = useDateRange()
  const { periodChecked, periodIndeterminate } = getPeriodCheckedState(form)
  const { savingAnalysisChecked, savingAnalysisIndeterminate } = getSavingAnalysisCheckedState(form)

  const onLabelClick = (type, title) => {
    const values = form.getFieldsValue();

    if (utilityFields.includes(type) && !values[type]) {
      message.warn("Please select a value");
      return;
    }
    setModalData({
      type: type,
      data: values,
      title
    });
    openModal();
  };

  const onFinish = () => {
    form.validateFields().then(({ endingMonth, rangeMonth, ...values }) => {
      const formatValues = {};
      if (endingMonth) {
        formatValues.endingMonth = endingMonth.format("YYYY-MM-DD");
      }
      if (Array.isArray(rangeMonth)) {
        formatValues.rangeMonth = rangeMonth.map((item) => item.format("YYYY-MM-DD"));
      }
      setPdfFormValue({
        ...values,
        ...formatValues,
      });
      goToLoadingStep();
    });
  };

  const onRecentlyCheckedChange = (e) => {
    const checked = e.target.checked;
    form.setFieldValue("periodItem", checked ? summaryChartClassnames.map((item) => item.id) : [])
    update();
  };

  const onSavingAnalysisChange = (e) => {
    const checked = e.target.checked;
    form.setFieldValue(
      "savingAnalysisItem",
      checked ? savingAnalysisOptions.map((item) => item.id) : []
    );
    update();
  };

  const validatorSavingAnalysis = (form) => {
    const { savingAnalysisItem, savingAnalysis } = form.getFieldsValue();

    if (form) {
      return {
        validator() {
          const hasSavingAnalysisItem = savingAnalysisItem?.length > 0;
          const isEmptyValue =
            !savingAnalysis ||
            isEmptyRangeDate(savingAnalysis.elec_Post_Date) ||
            isEmptyRangeDate(savingAnalysis.gas_Post_Date) ||
            isEmptyRangeDate(savingAnalysis.gas_Baseline_Date) ||
            isEmptyRangeDate(savingAnalysis.elec_Baseline_Date);

          if (hasSavingAnalysisItem && isEmptyValue) {
            return Promise.reject("Please Select Date");
          } else {
            return Promise.resolve();
          }
        },
      };
    }
  };

  const setSavingAnalysisState = (state) => {
    form.setFieldValue("savingAnalysis", {
      ...state,
    });
  };

  const copySavingAnalysisElecToGas = () => {
    const state = form.getFieldValue("savingAnalysis");
    form.setFieldValue("savingAnalysis", {
      ...state,
      gas_Baseline_Date: state.elec_Baseline_Date,
      gas_Post_Date: state.elec_Post_Date,
      gas_Option: state.elec_Option,
      gas_Custom_Price: state.elec_Custom_Price,
      gas_usedModuleValue: state.elec_usedModuleValue,
      gas_breakpoint0: state.elec_breakpoint0,
      gas_breakpoint1: state.elec_breakpoint1,
      gas_breakpoint2: state.elec_breakpoint2,
      gas_breakpoint3: state.elec_breakpoint3,
      gas_n_segments: state.elec_n_segments,
    });
  };


  const renderSummaryForm = () => {
    if (period === 'custom') {
      return <Form.Item name="rangeMonth" label="Range Month">
        <DatePicker.RangePicker disabledDate={(current) => {
          const max = safemomentmax(moment(dateRange.date_range_elec_max), moment(dateRange.date_range_gas_max))
          const min = safemomentmin(moment(dateRange.date_range_elec_min), moment(dateRange.date_range_gas_min))
          return current.isAfter(max, 'month') || current.isBefore(min, 'month')
        }} picker="month" style={{ width: 240 }} allowClear={false} />
      </Form.Item>
    } else if (period === 'YTD') {
      return null
    } else if (period === 'All') {
      return <Form.Item name="allRangeMonth" label="Range Month">
        <DatePicker.RangePicker disabled={true} picker="month" style={{ width: 240 }} allowClear={false} />
      </Form.Item>
    } else {
      return <Form.Item name="endingMonth" label="Ending Month">
        <DatePicker picker="month" disabledDate={(current) => {
          const max = safemomentmax(moment(dateRange.date_range_elec_max), moment(dateRange.date_range_gas_max))
          const min = safemomentmin(moment(dateRange.date_range_elec_min), moment(dateRange.date_range_gas_min))
          return current.isAfter(max, 'month') || current.isBefore(min, 'month')
        }} style={{ width: 140 }} />
      </Form.Item>
    }
  }

  return (
    <>
      <Modal
        // forceRender={true}
        open={open}
        onCancel={closeModal}
        title={<div className="flex justify-center">
          {modalData.title}
        </div>}
        width="90%"
        footer={null}
      >
        <PreView {...modalData} key={JSON.stringify(modalData)} />
      </Modal>
      <Form
        // initialValues={initialValues}
        form={form}
        onFinish={onFinish}
      >
        <MenuActionsWrap>
          <Button type="primary" htmlType="submit">
            Preview
          </Button>
        </MenuActionsWrap>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <div className="flex items-center">
          <div style={{ fontWeight: 550, color: '#6A74A5', marginBottom: 24 }}>Dashboard<Tooltip title="Preview">
            <FundViewOutlined
              style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
              onClick={() => onLabelClick("dashboard", "Dashboard")}
            />
          </Tooltip>：
          </div>
          <Form.Item
            name="dashboardVisible"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </div>

        <Form.Item
          name="utilityVisible"
          label="Utility"
          valuePropName="checked"
        >
          <Checkbox onChange={update} />
        </Form.Item>

        <Visible visible={utilityVisible}>
          <CardWrap>
            <CardItem>
              <div className="flex">
                <Form.Item>
                  <Checkbox
                    checked={periodChecked}
                    onChange={onRecentlyCheckedChange}
                    indeterminate={periodIndeterminate}
                    style={{ marginRight: 10 }}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <ViewLabel
                      label="Summary"
                      onClick={() => onLabelClick("period", "Summary")}
                    />
                  }
                >
                </Form.Item>
                <Form.Item label="Range" name="period" rules={[
                  (form) => {
                    const { periodItem } = form.getFieldsValue();
                    if (periodItem?.length > 0) {
                      return {
                        required: true,
                        message: "Please select recently summary",
                      };
                    }
                  },
                ]} style={{ marginBottom: 10, marginRight: 30, marginLeft: 16, width: 220 }}>
                  <Select style={{ width: 140, marginRight: 12, userSelect: 'none' }} >
                    <Select.Option value="custom">Custom</Select.Option>
                    <Select.Option value="1">1 Month</Select.Option>
                    <Select.Option value="3">3 Month</Select.Option>
                    <Select.Option value='6'>6 Month</Select.Option>
                    <Select.Option value="YTD">YTD</Select.Option>
                    <Select.Option value="12">1 Year</Select.Option>
                    <Select.Option value="24">2 Year</Select.Option>
                    <Select.Option value="36">3 Year</Select.Option>
                    <Select.Option value="60">5 Year</Select.Option>
                    <Select.Option value="All">All</Select.Option>
                  </Select>
                </Form.Item>
                {renderSummaryForm()}
              </div>

              <Form.Item name="periodItem" label="">
                <Checkbox.Group style={{ width: "100%" }} onChange={update}>
                  {renderPeriodItems()}
                </Checkbox.Group>
              </Form.Item>
            </CardItem>
            <CardItem style={{ marginTop: 12 }}>
              <div className="flex">
                <Form.Item>
                  <Checkbox
                    checked={savingAnalysisChecked}
                    onChange={onSavingAnalysisChange}
                    style={{ marginRight: 10 }}
                    indeterminate={savingAnalysisIndeterminate}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <ViewLabel
                      label="Savings"
                      onClick={() => onLabelClick("savingAnalysis", "Baseline and Energy Saving analysis")}
                    />
                  }
                ></Form.Item>
              </div>
              <ScenarioForm
                setSavingAnalysisState={setSavingAnalysisState}
                copySavingAnalysisElecToGas={copySavingAnalysisElecToGas}
              />
              <Form.Item name="savingAnalysis" rules={[validatorSavingAnalysis]}>
                <SavingAnalysisForm />
              </Form.Item>
              <Form.Item name="savingAnalysisItem" label="">
                <Checkbox.Group style={{ width: "100%" }} onChange={update}>
                  {renderSavingAnalysisItem()}
                </Checkbox.Group>
              </Form.Item>
            </CardItem>
          </CardWrap>
        </Visible>
      </Form>
    </>
  );
};

const isEmptyRangeDate = (date) => {
  return !date || date?.length !== 2;
};

const utilityFields = ["period", "recently", "savingAnalysis"]

// export const periodOptions = Object.values(PeridCardIdMappings);
export const savingAnalysisOptions = Object.values(SavingAnalysisCardIdMappings);

const renderPeriodItems = () => {
  return (
    <>
      <Row>
        {summaryChartClassnames.map(({ id, title }) => {
          return (
            <Col span={7} key={id}>
              <Checkbox value={id}><div className="overflow-ellipsis overflow-hidden whitespace-nowrap">{title}</div> </Checkbox>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

const renderSavingAnalysisItem = () => {
  return (
    <>
      <Row>
        {savingsChartClassnames.map(({ id, title }) => {
          return (
            <Col span={7} key={id}>
              <Checkbox value={id}>{title}</Checkbox>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

const ViewLabel = ({ label, onClick }) => {
  return (
    <>
      {label}
      <Tooltip title="Preview">
        <FundViewOutlined
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
          onClick={onClick}
        />
      </Tooltip>
    </>
  );
};

const getPeriodCheckedState = (form) => {
  const periodItemLength = form.getFieldValue("periodItem").length;
  return {
    periodChecked: periodItemLength === summaryChartClassnames.length,
    recentlyIndeterminate: periodItemLength !== summaryChartClassnames.length && periodItemLength !== 0,
  }
}

const getSavingAnalysisCheckedState = (form) => {
  const savingAnalysisItemLength = form.getFieldValue("savingAnalysisItem").length;
  return {
    savingAnalysisChecked: savingAnalysisItemLength === savingsChartClassnames.length,
    savingAnalysisIndeterminate: savingAnalysisItemLength !== savingsChartClassnames.length && savingAnalysisItemLength !== 0,
  }
}

export const utilityCardIdMappings = {
  Electricity: {
    Consumption: {
      id: 'ChartId_ElectricityConsumption',
      title: 'Electricity Consumption (kWh)',
    },
    PricePer: {
      id: 'ChartId_ElectricityPricePerkWh',
      title: 'Electricity Price Per kWh ($)'
    },
    Cost: {
      id: 'ChartId_ElectricityCost',
      title: 'Electricity Cost ($)',
    }
  },
  Gas: {
    Consumption: {
      id: 'ChartId_GasConsumption',
      title: 'Gas Consumption (MMBtu)',
    },
    PricePer: {
      id: 'ChartId_GasPricePer',
      title: 'Gas Price Per MMBtu ($)'
    },
    Cost: {
      id: 'ChartId_GasCost',
      title: 'Gas Cost ($)',
    }
  },
  Total: {
    TotalCost: {
      id: 'ChartId_TotalCost',
      title: 'Total Cost ($)',
    },
    TotalCostPerSqft: {
      id: 'ChartId_TotalCostPerSqft',
      title: 'Total Cost Per Sqft ($/sqft)',
    },
    TotalCostBreakdown: {
      id: 'ChartId_TotalCostBreakdown',
      title: 'Total Cost Breakdown ($)',
    }
  },
  EUI: {
    EUILast12Month: {
      id: 'ChartId_EUILast12Month',
      title: 'EUI Last 12 Month (kBtu/sqft-yr)',
    },
    EUIRolling12MonthsBreakdown: {
      id: 'ChartId_EUIRolling12MonthsBreakdown',
      title: 'EUI Rolling 12 Months Breakdown',
    },
    EUILast12MonthsBreakdown: {
      id: 'ChartId_EUILast12MonthsBreakdown',
      title: 'EUI Last 12 Month Breakdown',
    }
  }
}

export const utilityOptions = [
  ...Object.values(utilityCardIdMappings.Electricity),
  ...Object.values(utilityCardIdMappings.Gas),
  ...Object.values(utilityCardIdMappings.Total),
  ...Object.values(utilityCardIdMappings.EUI),
];

const EnddingMonthDatePicker = (props) => {

  const [value, setValue] = useControllableValue(props)

  return <DatePicker picker="month" disabledDate={(current) => current > moment().endOf('month')} style={{ width: 180 }} value={moment(value)} onChange={(e) => {
    setValue(moment(e).format('YYYY-MM'))
  }} />
}

export default PdfForm;
