import React from 'react';
import PermissionControl from "@components/Permission/Permission";

import Equipment from "@containers/facility/Equipment/Equipment";
import Workorder from "@containers/facility/Workorder/Workorder";
import Overview from "@containers/facility/Overview/Overview";
import Benchmark from "@containers/facility/Benchmark/Benchmark";
import FAQ from "@containers/Help/FAQ";
import Profile from "@containers/facility/Profile/Profile";
import Project from "@containers/facility/ProjectManagement/Project/Project";
import StationTemperature from "@containers/facility/StationTemperature/StationTemperature";
import Scenario from "@containers/facility/Scenario/Scenario";
import Log from "@containers/UtilityData/Log/Log";
import PdfReport from "@containers/PdfReport";
import NotificationDetail from "@containers/facility/Notification/NotificationDetail";
import AllComments from "@containers/facility/Comments/AllComments";
import FileManage from "@containers/FileManage";
import NewSummary from "@containers/UtilityData/New-Summary";
import GasPage from "@containers/UtilityData/GasPage";
import ElectricityPage from "@containers/UtilityData/ElectricityPage";
import SavingsPage from '@/containers/UtilityData/SavingsPage';
import TagManage from '@/containers/Tags/TagManage';

export const facilityRoutes = [
  {
    path: 'profile',
    element: <Profile />,
    name: 'profile',
    handle: {
      tab: 'Profile'
    }
  },
  {
    path: 'overview',
    element: <PermissionControl allowedPermissions={'1:overview'}><Overview /></PermissionControl>,
    name: 'overview',
    handle: {
      tab: 'Dashboard'
    }
  },
  {
    path: 'Dashboard',
    element: <PermissionControl allowedPermissions={'1:dashboard'}><Overview /></PermissionControl>,
    name: 'dashboard',
    handle: {
      tab: 'Dashboard'
    }
  },
  {
    path: 'UtilitySummary',
    element: <PermissionControl allowedPermissions={'1:electricity'}><NewSummary /></PermissionControl>,
    name: 'summary',
    handle: {
      tab: 'Summary'
    }
  },
  {
    path: 'Electricity',
    element: <PermissionControl allowedPermissions={'1:electricity'}><ElectricityPage /></PermissionControl>,
    name: 'electricity',
    handle: {
      tab: 'Electricity'
    }
  },
  {
    path: 'Gas',
    element: <PermissionControl allowedPermissions={'1:electricity'}><GasPage /></PermissionControl>,
    name: 'gas',
    handle: {
      tab: 'Gas'
    }
  },
  {
    path: 'Savings',
    element: <PermissionControl allowedPermissions={'1:electricity'}><SavingsPage /></PermissionControl>,
    name: 'savings',
    handle: {
      tab: 'Savings'
    }
  },
  {
    path: 'Benchmark',
    element: <PermissionControl allowedPermissions={'1:benchmark'}><Benchmark /></PermissionControl>,
    name: 'benchmark',
    handle: {
      tab: 'Benchmark'
    }
  },

  {
    path: 'workorder',
    element: <PermissionControl allowedPermissions={'1:workorder'}><Workorder /></PermissionControl>,
    name: 'workorder',
    handle: {
      tab: 'Workorder'
    }
  },
  {
    path: 'Equipment',
    element: <PermissionControl allowedPermissions={'1:equipment'}><Equipment /></PermissionControl>,
    name: 'equipment',
    handle: {
      tab: 'Equipment'
    }
  },
  {
    path: 'planning',
    element: <PermissionControl allowedPermissions={'1:planning'}><Project /></PermissionControl>,
    name: 'planning',
    handle: {
      tab: 'Project'
    }
  },
  {
    path: 'stationtemperature',
    element: <PermissionControl allowedPermissions={'1:stationtemperature'}><StationTemperature /></PermissionControl>,
    name: 'stationtemperature',
    handle: {
      tab: 'Station Temperature'
    }
  },
  {
    path: 'scenario',
    element: <PermissionControl allowedPermissions={'1:scenario'}><Scenario /></PermissionControl>,
    name: 'scenario',
    handle: {
      tab: 'Scenario'
    }
  },
  {
    path: 'FAQ',
    element: <PermissionControl allowedPermissions={'1:faq'}><FAQ /></PermissionControl>,
    name: 'faq',
    handle: {
      tab: 'FAQ'
    }
  },
  {
    path: 'log',
    element: <PermissionControl allowedPermissions={'1:log'}><Log /></PermissionControl>,
    name: 'log',
    handle: {
      tab: 'Log'
    }
  },
  {
    path: 'Pdf',
    element: <PermissionControl allowedPermissions={'1:customreport'}><PdfReport /></PermissionControl>,
    name: 'pdf',
    handle: {
      tab: 'Report'
    }
  },
  {
    path: 'FileManage',
    element: <PermissionControl allowedPermissions={'1:filemanage'}><FileManage /></PermissionControl>,
    name: 'file_manage',
    handle: {
      tab: 'File Manage'
    }
  },
  {
    path: 'notification',
    element: <PermissionControl allowedPermissions={'1:notification'}><NotificationDetail /></PermissionControl>,
    name: 'notification',
    handle: {
      tab: 'Notification'
    }
  },
  {
    path: 'comments',
    element: <PermissionControl allowedPermissions={'1:comments'}><AllComments /></PermissionControl>,
    name: 'comments',
    handle: {
      tab: 'Comments'
    }
  },
  {
    path: 'tags',
    element: <PermissionControl allowedPermissions={'1:tags'}><TagManage /></PermissionControl>,
    name: 'tags',
    handle: {
      tab: 'Tags'
    }
  },
]