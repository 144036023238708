import React from 'react';
import ReactECharts from "@/components/EChartsReact";
import moment from 'moment';

const ClassName_Savings_ElecBaseline = 'ChartsClassName-Savings-ElecBaseline';
const ClassName_Savings_GasBaseline = 'ChartsClassName-Savings-GasBaseline';

export const config = {
    gas: {
        title: 'Monthly Gas Usage Vs Baseline',
        yTitle: 'MMBTU',
        baselineColor: '#c00000',
        postPeriodColor: '#ffc000',
        className: ClassName_Savings_GasBaseline
    },
    elec: {
        title: 'Monthly Electricity Usage Vs Baseline',
        yTitle: 'kWh',
        baselineColor: '#4472c4',
        postPeriodColor: '#ffc000',
        className: ClassName_Savings_ElecBaseline
    },
}

export const MonthlyUsageBaselineChart = ({ type, data }) => {
    // 处理数据,将数据分成两组
    const baselineData = data
        .filter(item => item.type === 'Baseline')
        .map(item => [item.date, item.value]);

    const postPeriodData = data
        .filter(item => item.type === 'Post Period')
        .map(item => [item.date, item.value]);

    // 计算所有数据的最大值和最小值
    const allValues = [...baselineData, ...postPeriodData].map(item => item[1]);
    const minValue = Math.min(...allValues);
    const maxValue = Math.max(...allValues);

    // 计算合适的Y轴范围
    const range = maxValue - minValue;
    const padding = range * 0.1; // 添加10%的padding
    const yMin = Math.floor((minValue - padding) / 1000) * 1000;
    const yMax = Math.ceil((maxValue + padding) / 1000) * 1000;

    const option = {
        title: {
            text: config[type].title,
            left: '1%',
            top: '2%',
            textStyle: {
                color: '#396598',
                fontSize: 14
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'none'
            },
            formatter: function (params) {
                return params.map(param => {
                    const formattedValue = Math.round(param.value[1]).toLocaleString();
                    const color = param.seriesName === 'Baseline' ? config.baselineColor : config.postPeriodColor;
                    const marker = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:${color};"></span>`;
                    return `${marker}${param.seriesName}: ${formattedValue} ${config[type].yTitle}`;
                }).join('<br/>');
            }
        },
        legend: {
            data: ['Baseline', 'Post Period'],
            top: '10%',
            itemWidth: 10,
            itemHeight: 10
        },
        grid: {
            left: type === 'gas' ? '6%' : '4%',
            right: '2%',
            bottom: '1%',
            top: '20%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: [...new Set(data.map(item => item.date))],
                axisLabel: {
                    rotate: 50,
                    formatter: function (value) {
                        return moment(value).format('MMM');
                    }
                },
                position: 'bottom'
            },
            {
                type: 'category',
                position: 'top',
                boundaryGap: true,
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    formatter: function (value) {
                        return moment(value).format('YYYY');
                    }
                },
                data: [...new Set(data.map(item => item.date))]
            }
        ],
        yAxis: {
            type: 'value',
            name: config[type].yTitle,
            nameLocation: 'middle',
            nameGap: type === 'gas' ? 30 : 50,
            nameTextStyle: {
                fontWeight: 'bold',
                color: '#6e7079'
            },
            axisLine: {
                show: true
            },
            min: yMin,
            max: yMax,
            interval: (yMax - yMin) / 5,
            axisLabel: {
                formatter: function (value) {
                    const absValue = Math.abs(value);
                    if (absValue >= 1000) {
                        return `${value < 0 ? '-' : ''}${Math.round(absValue / 1000)}K`;
                    }
                    return value;
                }
            }
        },
        series: [
            {
                name: 'Baseline',
                type: 'line',
                data: baselineData,
                symbolSize: 8,
                symbol: 'circle',
                itemStyle: {
                    borderWidth: 2,
                    borderColor: config[type].baselineColor,
                    color: '#fff'
                },
                lineStyle: {
                    width: 2,
                    color: config[type].baselineColor
                },
            },
            {
                name: 'Post Period',
                type: 'line',
                data: postPeriodData,
                symbolSize: 8,
                symbol: 'circle',
                itemStyle: {
                    borderWidth: 2,
                    borderColor: config[type].postPeriodColor,
                    color: '#fff'
                },
                lineStyle: {
                    width: 2,
                    color: config[type].postPeriodColor
                },
            }
        ]
    };

    return (
        <ReactECharts
            className={config[type].className}
            option={option}
            style={{ height: '100%', width: '100%' }}
        />
    );
};