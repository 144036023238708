import React, {Component} from 'react';
import EditableTable from "@components/Table/EditableTable";
import {FacilityColumns, FacilityProps} from "@containers/management/Facility/FacilityColumns";
import _ from "lodash";
import actions from "@redux/facility/actions";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {FacilityFormFields} from "@containers/management/Facility/FacilityFormFields";
import GoogleMaps from "@components/GoogleMaps";
import {FacilityColumnsCompact} from "@containers/management/Facility/FacilityColumnsCompact";
import notification2 from "@components/Notification2";
import Lookup_Common from "@components/Lookup_common";
import {FacilityFilters} from "@containers/management/Facility/FacilityFilter";
import {removeDuplicates} from "@components/help";
import authActions from "@redux/auth/actions";
import CustomUserRelated from "@containers/management/Facility/CustomUserRelated/CustomUserRelated";
import {CustomUserFilters} from "@containers/management/UserManagement/CustomUser/CustomUserFilter";
import SearchLookup from '@/components/SearchLookup';

class Facility extends Component {

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            isLoading: false,
            tableRef: null,
            filterDescription: null,
            query: null,
            filtered_category: null,
            filtered_company: null,
        }
    }


    handleCreateFacility = (body) => {

        let parent = this.props.relatedList;
        if (parent) {
            body['company'] = this.props.relatedId;
        }

        if (this.props.SelectedCompany) {
            body['company'] = this.props.SelectedCompany;
        }

        if (!body['longitude']) {
            body['longitude'] = 0
        }
        if (!body['latitude']) {
            body['latitude'] = 0
        }
        return api.createFacility(body).then(
            response => {
                // this.refreshFacilityOption(response.data);
                this.refreshUserInfo();
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteFacility = (id) => {
        return api.deleteFacility(id).then(
            response => {

                this.refreshUserInfo();
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkInsert = (body) => {
        return api.bulkFacilityInsert(body).then(
            response => {
                return response.data
            }
        ).catch(error => {
            return (
                error
            )
        });
    }

    handleBulkDelete = (body) => {
        return api.bulkDeleteFacility(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {
        // console.log('row0', row, this.props.SelectedCompany)

        if (this.props.SelectedCompany) {
            row['company'] = this.props.SelectedCompany;
        }
        row['user'] = []
        if (!row['longitude']) {
            row['longitude'] = 0
        }
        if (!row['latitude']) {
            row['latitude'] = 0
        }

        let parent = this.props.relatedList;
        if (parent) {
            row['company'] = this.props.relatedId;
        }

        if(Array.isArray(row.address)) {
            delete row.address
        }

        return api.editFacility(key, row).then(
            response => {
                // this.refreshFacilityOption(response.data)
                this.refreshUserInfo(key);


                return response
            }
        ).catch(error => (
            error
        ));
    }


    handleFilter = (query) => {

        const {filtered_company, filtered_category} = this.state;
        let filterDescription = [filtered_company, filtered_category]
            .filter(Boolean)
            .join(' ');

        filterDescription = (filterDescription.length > 0 && filterDescription !== ' ')
            ? `filtered by: ${filterDescription}`
            : '';

        this.setState({
            isLoading: true,
            filterDescription: filterDescription
        })

        let query_new =
            (this.state.filtered_company ? ('&company=' + (this.state.filtered_company)) : '') +
            (this.state.filtered_category ? ('&category=' + (this.state.filtered_category)) : '')
        ;

        query_new = query+ '&'+this.state.query + '&' + query_new

        let parent = this.props.relatedList;
        if (parent) {
            if (this.props.relatedId) {
                query_new += 'company=' + this.props.relatedId
            }
        }

        return api.filterFacility(query_new).then(
            response => {
                let dataSource = response.data.results
                // this.refreshUserInfo();
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count
                }
            }
        ).catch(error => (
            error
        ));
    }

    getFacility = (data) => {

        let category_options = [];
        // status_options.push({label: 'All', value:'All'});

        let company_options = [];
        // priority_options.push({label: 'All', value:'All'});
        for (let i = 0; i < data.length; i++) {
            company_options.push({label: data[i].company_name, value: data[i].company});
            category_options.push({label: data[i].category, value: data[i].category});
        }


        this.setState({
            data,
            isLoading: false,
            company_options: removeDuplicates(company_options),
            category_options: removeDuplicates(category_options),
        })


    }

    refreshUserInfo = (key) => {
        const that = this;
        return api.refreshUserInfo().then(
            response => {
                let data = response.data;
                let facilities = _.get(data, 'user_facilities', []);
                this.refreshFacilityOption(facilities);
                this.props.updateUserInfoRequest(data);
                if(that.props.selectedId === key) {
                    const currentFacility = facilities.find(f => f.id === that.props.selectedId)
                    that.props.UpdateSelectedFacility({
                        key: currentFacility.id + '',
                        Id: currentFacility.id,
                        value: currentFacility.name,
                        record: currentFacility,
                    })
                }
            }
        ).catch(error => (
            error
        ))
    }
    refreshFacilityOption = (facilities) => {


        let facilityOptions = [];
        facilities.forEach(facility => {
            facilityOptions.push(
                {
                    Id: facility.id, label: (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            {facility.name}
                            <span>{facility.company}</span>
                        </div>
                    ), value: facility.name, record: facility, index: facility.id
                }
            )
        })

        this.props.setFacilityRequest(facilityOptions)
    }

    gettableRef = (tableRef) => {
        console.log('tableRef', tableRef)
        this.setState({
            tableRef
        })
    }

    renderFilter = () => {
        return (
            <div className={'flex flex-wrap items-center'}>
                <SearchLookup
                    placeholder={'Name'}
                    name={'name'}
                    style={{width: '100%', paddingRight: '2rem'}}
                    SelectedLookup_CommonRequest={this.handleSearchSelect}
                    Lookup_CommonupRequest={this.handleSearch}
                />
                <FacilityFilters
                    category_options={this.state.category_options}
                    company_options={this.state.company_options}
                    handleFilter={this.setFilters}
                />
            </div>
        )
    }

    setFilters = (e) => {
        this.setState({
            filtered_company: e.Company ? e.Company : [],
            filtered_category: e.Category ? e.Category : [],
        }, function () {
            this.refresh(false)
        })
    }
    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };
    handleSearchSelect = (e) => {
        let query = 'name=' + e;
        this.setState({
            query: query
        }, function () {
            this.refresh(false)
        })

    }
    handleSearch = (e, record) => {
        let query = 'name=' + e;
        this.setState({
            query: query
        })
        return this.handleFilter()
    }

    RenderCustomUserRelated = (relatedId) => {
        return (<CustomUserRelated relatedId={relatedId} />)
    }

    render() {

        let parent = this.props.relatedList;
        let columns = [];
        if (parent) {
            columns = FacilityColumnsCompact()
        } else {
            columns = FacilityColumns()
        }

        return (

            <div className="m-5">
                <EditableTable
                    ref={this.tableRef}
                    columns={columns}
                    data={this.state.data}
                    columnProps={FacilityProps}
                    formFields={FacilityFormFields(this.state.tableRef)}
                    formRelated={this.RenderCustomUserRelated}
                    getRecord={this.getFacility}
                    createRecords={this.handleCreateFacility}
                    deleteRecord={this.handleDeleteFacility}
                    bulkInsert={this.handleBulkInsert}
                    bulkDelete={this.handleBulkDelete}
                    handOnlineSave={this.handOnlineSave}
                    relatedList={this.props.relatedList}
                    hide_date={true}
                    hide_imports={true}
                    hide_search={true}
                    hid_new={this.props.Role === '4.0'}
                    isChat={false}
                    isRelated={true}
                    isAttachments={false}
                    getFormRef={this.gettableRef}

                    handleFilter={this.handleFilter}
                    renderFilter={this.renderFilter}
                    setFilters={this.setFilters}

                    filterDescription={this.state.filterDescription}

                    renderSearches={this.renderSearches}
                />
                {/*<AddressLookup />*/}
                {!this.props.relatedList ?
                    <div className='mt-5'>
                        <GoogleMaps data={this.state.data} height={'40vh'} zoom={3.5}/>
                    </div>
                    : ''}
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        Facility: _.get(state.Facility, 'data'),
        SelectedCompany: _.get(state.Company, 'selectedCompany.Id'),
        Role: _.get(state, 'Auth.role') ? Object.keys(_.get(state, 'Auth.role'))[0] : null,
        selectedId: _.get(state.Facility, 'selected.Id')
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setFacilityRequest: (e) => dispatch(actions.FacilitySuccess(e)),
        updateUserInfoRequest: (e) => dispatch(authActions.userInfoUpdated(e)),
        UpdateSelectedFacility: (e) => dispatch(actions.SelectedFacility(e))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Facility);
