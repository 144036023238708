import actions from './actions';

const initState = {
    access: null,
    refresh: null,
    accessTokenExpiration: null,
    refreshTokenExpiration: null,
    user: null,
    image: null,
    permission: [],
    Email: [],
    role: null,
    company: [],
    first_name: null,
    last_name: null,
    middle_name: null,
    IPAddress: [],
    isInCompany: false,
    selected_userpermissions: []
};
export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:

            return {
                ...state,
                access: action.access,
                refresh: action.refresh,
                accessTokenExpiration: action.accessTokenExpiration,
                refreshTokenExpiration: action.refreshTokenExpiration,
                user: action.user,
                userId: action.userId,
                image: action.image,
                company: action.company,
                user_facilities: action.user_facilities,
                is_companyadmin: action.is_companyadmin,
                permission: action.permission,
                role: action.role,
                first_name: action.first_name,
                last_name: action.last_name,
                middle_name: action.middle_name,
                email: action.email,
            };
        case actions.UPDATE_TOKEN:
            return {
                ...state,
                access: action.payload.access,
                refresh: action.payload.refresh,
                accessTokenExpiration: action.payload.accessTokenExpiration,
                refreshTokenExpiration: action.payload.refreshTokenExpiration,
            }
        case actions.USERINFO_UPDATE_SUCCESS:
            return {
                ...state,
                user: action.user,
                userId: action.userId,
                image: action.image,
                company: action.company,
                user_facilities: action.user_facilities,
                is_companyadmin: action.is_companyadmin,
                permission: action.permission,
                role: action.role,
                first_name: action.first_name,
                last_name: action.last_name,
                middle_name: action.middle_name,
                email: action.email,
            };
        case actions.USER_UPDATE:
            return {
                ...state,
                ...action.payload
            };
        case actions.IPADDRESS:
            return {
                ...state,
                IPAddress: action.payload.data
            }

        case actions.SELECT_USERPERMISSIONS:
            return {
                ...state,
                selected_userpermissions: action.payload.data
            }

        case actions.IS_IN_COMPANY:
            return {
                ...state,
                isInCompany: action.payload.data
            }
        case actions.LOGOUT:
            return initState;
        default:
            return state;
    }
}
