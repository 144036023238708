import React from 'react';
import ReactECharts from "@/components/EChartsReact";
import moment from 'moment';

const ClassName_Savings_CumulativeCost = 'ChartsClassName-Savings-CumulativeCost';

export const config = {
    title: 'Cumulative Cost Savings',
    className: ClassName_Savings_CumulativeCost
}

export const CumulativeCostSavingsChart = ({ data }) => {
    // 处理数据，分离电力和燃气数据
    const gasData = data
        .filter(item => item.type === 'Gas')
        .map(item => [item.date, item.value]);
    
    const electricityData = data
        .filter(item => item.type === 'Electricity')
        .map(item => [item.date, item.value]);

    // 计算Y轴范围
    const calculateStackedValues = () => {
        const dateSet = new Set(data.map(item => item.date));
        const stackedValues = Array.from(dateSet).map(date => {
            const gasValue = gasData.find(item => item[0] === date)?.[1] || 0;
            const electricityValue = electricityData.find(item => item[0] === date)?.[1] || 0;
            return gasValue + electricityValue;
        });
        return stackedValues;
    };

    const stackedValues = calculateStackedValues();
    const minValue = Math.min(...stackedValues);
    const maxValue = Math.max(...stackedValues);
    const range = maxValue - minValue;
    const padding = range * 0.1;
    const yMin = Math.floor((minValue - padding) / 1000) * 1000;
    const yMax = Math.ceil((maxValue + padding) / 1000) * 1000;

    const option = {
        title: {
            text: config.title,
            left: '1%',
            top: '2%',
            textStyle: {
                color: '#396598',
                fontSize: 14
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'none'
            },
            formatter: function(params) {
                let total = 0;
                const result = params.map(param => {
                    const value = param.value[1];
                    total += value;
                    const formattedValue = `$${Math.round(value).toLocaleString()}`;
                    const color = param.seriesName === 'Gas' ? '#d30000' : '#4472c4';
                    const marker = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:${color};"></span>`;
                    return `${marker}${param.seriesName}: ${formattedValue}`;
                }).join('<br/>');
                
                // 添加总计行
                const totalLine = `<br/><div style="margin-top:5px;border-top:1px solid #ccc;padding-top:5px;">Total: $${Math.round(total).toLocaleString()}</div>`;
                return result + totalLine;
            }
        },
        legend: {
            data: ['Gas', 'Electricity'],
            top: '10%',
            itemWidth: 10,
            itemHeight: 10
        },
        grid: {
            left: '1%',
            right: '2%',
            bottom: '1%',
            top: '20%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: [...new Set(data.map(item => item.date))],
                axisLabel: {
                    rotate: 50,
                    formatter: function(value) {
                        return moment(value).format('MMM');
                    }
                },
                position: 'bottom'
            },
            {
                type: 'category',
                position: 'top',
                boundaryGap: true,
                axisLine: {
                    show: true
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    formatter: function(value) {
                        return moment(value).format('YYYY');
                    }
                },
                data: [...new Set(data.map(item => item.date))]
            }
        ],
        yAxis: {
            type: 'value',
            name: 'Cost ($)',
            nameLocation: 'middle',
            nameGap: 100,
            nameTextStyle: {
                fontWeight: 'bold',
                color: '#6e7079'
            },
            axisLine: {
                show: true
            },
            min: yMin,
            max: yMax,
            interval: (yMax - yMin) / 5,
            axisLabel: {
                formatter: function(value) {
                    const absValue = Math.abs(value);
                    if (absValue >= 1000) {
                        return `$${value < 0 ? '-' : ''}${Math.round(absValue / 1000)}K`;
                    }
                    return `$${value}`;
                }
            }
        },
        series: [
            {
                name: 'Electricity',
                type: 'line',
                stack: 'Total',
                stackStrategy: 'all',
                data: electricityData,
                symbolSize: 8,
                symbol: 'circle',
                itemStyle: {
                    borderWidth: 2,
                    borderColor: '#4472c4',
                    color: '#fff'
                },
                lineStyle: {
                    width: 2,
                    color: '#4472c4'
                },
                areaStyle: {
                    opacity: 0.8,
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0,
                            color: '#4472c4'
                        }, {
                            offset: 1,
                            color: 'rgba(68, 114, 196, 0.2)'
                        }]
                    }
                }
            },
            {
                name: 'Gas',
                type: 'line',
                stack: 'Total',
                stackStrategy: 'all',
                data: gasData,
                symbolSize: 8,
                symbol: 'circle',
                itemStyle: {
                    borderWidth: 2,
                    borderColor: '#d30000',
                    color: '#fff'
                },
                lineStyle: {
                    width: 2,
                    color: '#d30000'
                },
                areaStyle: {
                    opacity: 0.8,
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0,
                            color: '#d30000'
                        }, {
                            offset: 1,
                            color: 'rgba(211, 0, 0, 0.2)'
                        }]
                    }
                }
            }
        ]
    };

    return (
        <ReactECharts
            className={config.className}
            option={option}
            style={{ height: '100%', width: '100%' }}
        />
    );
}