import React, { useEffect, useState } from 'react';
import EChartsReact from "@/components/EChartsReact"
import moment from 'moment';
import { useSummaryData } from '../hooks/useSummaryData';
import { ClassName_MONTHLY_ELECTRICITY_USAGE_COSTS, ClassName_MONTHLY_GAS_USAGE_COSTS } from './summaryChartClassnames';

export const config = {
  gas: {
    title: 'Monthly Gas Usage and Costs',
    name: 'Gas',
    color: '#c00000',
    unit: 'MMbtu'
  },
  electricity: {
    title: 'Monthly Electricity Usage and Costs',
    name: 'Electricity',
    color: '#4472c4',
    unit: 'kWh'
  }
}

export const ConsumptionAndCostsCharts = ({ type, period, endingMonth }) => {

  const { data = {
    [type]: []
  } } = useSummaryData(type, period, endingMonth)
  const chartData = data[type]

  const classname = type === 'gas' ? ClassName_MONTHLY_GAS_USAGE_COSTS : ClassName_MONTHLY_ELECTRICITY_USAGE_COSTS;

  const option = {
    backgroundColor: '#fff',
    tooltip: {
      trigger: 'axis',
      formatter: function(params) {
        const date = moment(params[0].axisValue, "YYYY-M").format('MMM YYYY');
        let result = `${date}<br/>`;
        
        params.forEach(param => {
          const value = param.value;
          const marker = param.marker;
          const seriesName = param.seriesName;
          let formattedValue;
          
          if (seriesName === 'Costs') {
            formattedValue = `$ ${Math.round(value).toLocaleString()}`;
          } else {
            // Usage formatting
            if (type === 'gas') {
              formattedValue = `${Math.round(value).toLocaleString()} ${config[type].unit}`;
            } else {
              formattedValue = `${Math.round(value).toLocaleString()} ${config[type].unit}`;
            }
          }
          
          result += `${marker}${seriesName}: ${formattedValue}<br/>`;
        });
        
        return result;
      }
    },
    xAxis: [
      {
        type: 'category',
        data: chartData.map(item => item.month),
        axisLabel: {
          rotate: 50,
          formatter: function (value) {
            return moment(value, "YYYY-M").format("MMM");
          }
        },
        position: 'bottom'
      },
      {
        type: 'category',
        position: 'top',
        axisLine: {
          show: true
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          formatter: function (value) {
            return moment(value, "YYYY-M").format("YYYY");
          }
        },
        data: chartData.map(item => item.month)
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: config[type].unit,
        position: 'left',
        nameLocation: 'middle',
        nameGap: 38,
        axisLabel: {
          formatter: function (value) {
            if(type === 'gas') {
              return value >= 1000 ? (value / 1000).toFixed(1) + 'K' : parseInt(value);
            }
            return value >= 1000 ? (value / 1000).toFixed(type === 'gas' ? 1 : 0) + 'K' : parseInt(value);
          },
        },
      },
      {
        type: 'value',
        name: '',
        position: 'right',
        splitLine: {
            show: false
          },
        axisLabel: {
          formatter: function (value) {
            return '$' + (value >= 1000 ? (value / 1000).toFixed(0) + 'K' : parseInt(value));
          }
        }
      }
    ],
    series: [
      {
        name: 'Usage',
        type: 'bar',
        data: chartData.map(item => item.usage),
        itemStyle: {
          color: config[type].color,
          borderRadius: [6, 6, 0, 0]
        }
      },
      {
        name: 'Costs',
        type: 'line',
        yAxisIndex: 1,
        data: chartData.map(item => item.costs),
        itemStyle: {
          color: '#70ad47'
        }
      }
    ],
    title: {
      text: config[type].title,
      left: 'center',
      top: '2%',
      textStyle: {
        color: '#396598',
        fontSize: 14
      }
    },
    legend: {
      data: ['Usage', 'Costs'],
      left: 'center',
      top: '12%',
      itemWidth: 10,
      itemHeight: 10
    },
    grid: {
      top: '20%',
      left: '4%',
      right: '4%',
      bottom: '4%',  // 增加底部空间以适应旋转的标签
      containLabel: true
    },
  };

  return (
    <EChartsReact className={classname} option={option} style={{width: '100%', height: '100%'}} />
  );
}
