import { config as NormalizedUsageChartConfig } from "./NormalizedUsageChart";
import { config as MonthlyUsageBaselineChartConfig } from "./MonthlyUsageBaselineChart";
import { config as MonthlyCostSavingsChartConfig } from "./MonthlyCostSavingsChart";
import { config as CumulativeCostSavingsChartConfig } from "./CumulativeCostSavingsChart";

export const savingsChartClassnames = [
    {
        id: NormalizedUsageChartConfig.elec.className,
        title: NormalizedUsageChartConfig.elec.title
    },
    {
        id: NormalizedUsageChartConfig.gas.className,
        title: NormalizedUsageChartConfig.gas.title
    },
    {
        id: MonthlyUsageBaselineChartConfig.elec.className,
        title: MonthlyUsageBaselineChartConfig.elec.title
    },
    {
        id: MonthlyUsageBaselineChartConfig.gas.className,
        title: MonthlyUsageBaselineChartConfig.gas.title
    },
    {
        id: MonthlyCostSavingsChartConfig.className,
        title: MonthlyCostSavingsChartConfig.title
    },
    {
        id: CumulativeCostSavingsChartConfig.className,
        title: CumulativeCostSavingsChartConfig.title
    }
]
