import { Upload, Form } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { update_profile } from '@/redux/api';
import { useSelector, useDispatch } from 'react-redux';
import AuthActions from '@/redux/auth/actions';

const AvatarUploadWrapper = styled.div`
  .ant-upload-list-item {
    border: 0;
    border-radius: 50%;
    background-color: #fff;
    width: 160px;
    height: 160px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before,
  .ant-upload-list-item-info,
  .ant-upload.ant-upload-select-picture-card
   {
    border-radius: 50%;

  }
  .ant-upload {
    width: 160px;
    height: 160px;
  }
`

export const UserProfileAvatar = () => {
  const Auth = useSelector(state => state.Auth);
  const dispatch = useDispatch();
  const defaultImage = Auth.image;
  const userId = Auth.userId;

  const [fileList, setFileList] = useState(() => {
    return defaultImage ? [
      {
        uid: '-1',
        name: 'default-image.png',
        status: 'done',
        url: defaultImage,
      }
    ] : []
  })

  const onChange = ({ fileList: newFileList }) => {
    // 只保留最新的一个文件
    const latestFile = newFileList.length ? [newFileList[newFileList.length - 1]] : [];
    const updatedFileList = latestFile.map(file => {
      if (file.status === 'uploading') {
        return { ...file, status: 'done' };
      }
      return file;
    });
    setFileList(updatedFileList);

    if (updatedFileList.length > 0) {
      // update
      const avatarFile = updatedFileList[0].originFileObj;
      update_profile(userId, { image: avatarFile }, true).then(res => {
        if(res.status === 200) {
          const {image} = res.data;
          dispatch(AuthActions.userUpdate({
            image: image
          }));
        }
      })
    } else {
      // delete
      update_profile(userId, { image: null }, true, false).then(res => {
        if(res.status === 200) {
          dispatch(AuthActions.userUpdate({
            image: null
          }));
        }
      });
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const imgWindow = window.open(src);
    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    }
  };

  let resultFileList = fileList;

  return (
    <AvatarUploadWrapper>
      <ImgCrop shape="round" rotate modalTitle='Upload Avatar'>
        <Upload
          className="avatar-uploader"
          listType="picture-card"
          fileList={resultFileList}
          onChange={onChange}
          onPreview={onPreview}
          customRequest={({ onSuccess }) => {
            setTimeout(() => {
              onSuccess("ok", null);
            }, 0);
          }}
        >
          {resultFileList.length === 0 && '+ Upload'}
        </Upload>
      </ImgCrop>
    </AvatarUploadWrapper>
  );
};
